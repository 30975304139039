import React, { useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Button, Container, Typography, Card, CardContent, CardActions, Grid, Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import episodes from '../components/functions/episodes';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

function PodcastEpisodePage() {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [currentAudio, setCurrentAudio] = useState(null);
  const episode = episodes.find(episode => episode.slug === slug);
  const audioRefs = useRef([]);

  const handlePlay = (episode) => {
    if (currentAudio !== null && currentAudio !== episode.id) {
      audioRefs.current[currentAudio].audio.current.pause();
    }
    setCurrentAudio(episode.id);
  };

  const handlePause = () => {
    setCurrentAudio(null);
  };

  if (!episode) {
    return (
      <Box
        id='not-found-podcast'
        sx={{
          height: '74vw',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <SentimentVeryDissatisfiedIcon sx={{ fontSize: '128px', mb: 2, color: 'error.main' }} />
        <Typography variant="subtitle">Podcast episode not found</Typography>
        <Button
          size="small"
          color="primary"
          onClick={() => navigate('/podcasts')}
        >
          View all podcasts
        </Button>
      </Box>
    );
  }

  return (
    <HelmetProvider>
      <Helmet>
        <title>{episode.title}</title>
        <meta name="description" content={episode.description} />
        <meta property="og:title" content={episode.title} />
        <meta property="og:description" content={episode.description} />
        <meta property="og:image" content={episode.thumbnail || 'default-image-url'} />
        <meta property="og:url" content={`${window.location.origin}/podcasts/${episode.slug}`} />
        <meta property="og:type" content="article" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={episode.title} />
        <meta name="twitter:description" content={episode.description} />
        <meta name="twitter:image" content={episode.thumbnail || 'default-image-url'} />
        <link rel="canonical" href={`${window.location.origin}/podcasts/${episode.slug}`} />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "PodcastEpisode",
              "name": "${episode.title}",
              "description": "${episode.description}",
              "thumbnailUrl": "${episode.thumbnail}",
              "url": "${window.location.href}"
            }
          `}
        </script>
      </Helmet>
      <Box sx={{ bgcolor: 'background.default', padding: 3, marginTop: '60px' }}>
        <Container>
          <Box sx={{ marginTop: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={6} key={episode.id}>
                <Card>
                  <CardContent>
                    <img className='episode-thumbnail' src={episode.thumbnail} alt="audio thumbnail" />
                    <Typography variant="h5" component="div">
                      {episode.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ marginBottom: '8px' }}>
                      {episode.description}
                    </Typography>
                    <AudioPlayer
                      ref={(element) => { audioRefs.current[episode.id] = element; }}
                      src={episode.audioSrc}
                      onPlay={() => handlePlay(episode.id)}
                      onPause={handlePause}
                      onEnded={handlePause}
                      customAdditionalControls={[]}
                      customVolumeControls={[]}
                    />
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      color="primary"
                      startIcon={<ArrowBackIcon />}
                      onClick={() => navigate('/podcasts')}
                    >
                      All Podcasts
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </HelmetProvider>
  );
}

export default PodcastEpisodePage;
