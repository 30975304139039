import { Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import supabase from './supabaseClient';

const PrivateRoute = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      const { data: { session } } = await supabase.auth.getSession(); 
      setIsAuthenticated(!!session); 
      setLoading(false); 
    };

    checkAuth();
  }, []);

  if (loading) {
    return <div>Loading...</div>; 
  }

  return isAuthenticated ? children : <Navigate to="/login" />; 
};

export default PrivateRoute;
