import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, Paper } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import supabase from './supabaseClient'; 
import CryptoJS from 'crypto-js';
import { Link } from 'react-router-dom';

const hashCode = (code) => {
  return CryptoJS.SHA256(code).toString(CryptoJS.enc.Hex);
};

const LockScreen = ({ onUnlock }) => {
  const [code, setCode] = useState('');
  const [error, setError] = useState(false);
  const [hashedCodes, setHashedCodes] = useState([]);

  useEffect(() => {
    const fetchCodes = async () => {
      try {
        let { data, error } = await supabase
          .from('access_codes')
          .select('code');

        if (error) throw error;

        if (data) {
          setHashedCodes(data.map(item => item.code));
        }
      } catch (error) {
        console.error('Error fetching codes:', error);
      }
    };

    fetchCodes();
  }, []);

  const handleCodeChange = (e) => {
    setCode(e.target.value);
    setError(false);
  };

  const handleUnlock = () => {
    const hashedEnteredCode = hashCode(code);
    if (hashedCodes.includes(hashedEnteredCode)) {
      onUnlock();
    } else {
      setError(true);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#f0f0f0',
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: '20px',
          textAlign: 'center',
          width: '90%',
          maxWidth: '400px',
          backgroundColor: '#ffffff',
          borderRadius: '10px',
        }}
      >
        <LockIcon sx={{ fontSize: 50, color: 'primary.main' }} />
        <Typography variant="h5" sx={{ margin: '20px 0', color: 'primary.main' }}>
          Welcome!
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '20px', color: 'text.secondary' }}>
          The reading room is exclusive to subscribers only. Kindly go to our <Link to='/buy' style={{ textDecoration: 'none' }}> buy page </Link> 
           to purchase the soft copy tier in order to get the access code.
        </Typography>
        <TextField
          label="Enter Code"
          variant="outlined"
          value={code}
          onChange={handleCodeChange}
          error={error}
          helperText={error ? 'Incorrect code, please try again.' : ''}
          fullWidth
          sx={{ marginBottom: '20px' }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleUnlock}
          sx={{ width: '100%' }}
        >
          Gain Access
        </Button>
      </Paper>
    </Box>
  );
};

export default LockScreen;