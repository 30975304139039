import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Box, Button, Card, CardActions, CardContent, CardMedia, Typography, Avatar, Container, Grid, IconButton } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import blogs from '../components/functions/blogs'; 
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

function BlogPostPage() {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [bookmarkedBlogs, setBookmarkedBlogs] = React.useState([]);
  const blog = blogs.find(blog => blog.slug === slug);

  React.useEffect(() => {
    const savedBookmarks = JSON.parse(localStorage.getItem('bookmarkedBlogs')) || [];
    setBookmarkedBlogs(savedBookmarks);
  }, []);

  React.useEffect(() => {
    localStorage.setItem('bookmarkedBlogs', JSON.stringify(bookmarkedBlogs));
  }, [bookmarkedBlogs]);

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: blog.title,
        text: blog.snippet,
        url: window.location.href,
      })
    } else {
      alert('Your browser does not support sharing. Please copy the link manually.');
    }
  };

  const handleBookmark = (blog) => {
    setBookmarkedBlogs(prev => {
      const isBookmarked = prev.some(item => item.id === blog.id);
      if (isBookmarked) {
        return prev.filter(item => item.id !== blog.id);
      } else {
        return [blog, ...prev];
      }
    });
  };

  if (!blog) {
    return (
      <Box
        id='not-found'
        sx={{
          height: '74vw',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <SentimentVeryDissatisfiedIcon sx={{ fontSize: '128px', mb: 2, color: 'error.main' }} />
        <Typography variant="subtitle">Blog post not found</Typography>
        <Button
          size="small"
          color="primary"
          onClick={() => navigate('/blogs')}
        >
          View all blogs
        </Button>
      </Box>
    );
  }

  return (
    <HelmetProvider>
      <Helmet>
        <title>{blog.title}</title>
        <meta name="description" content={blog.snippet} />
        <meta property="og:title" content={blog.title} />
        <meta property="og:description" content={blog.snippet} />
        <meta property="og:image" content={blog.thumbnail || 'default-image-url'} />
        <meta property="og:url" content={`${window.location.origin}/blogs/${blog.slug}`} />
        <meta property="og:type" content="article" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={blog.title} />
        <meta name="twitter:description" content={blog.snippet} />
        <meta name="twitter:image" content={blog.thumbnail || 'default-image-url'} />
        <link rel="canonical" href={`${window.location.origin}/blogs/${blog.slug}`} />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Article",
              "headline": "${blog.title}",
              "description": "${blog.snippet}",
              "image": "${blog.thumbnail}",
              "author": {
                "@type": "Person",
                "name": "${blog.author}"
              },
              "datePublished": "${blog.date}",
              "publisher": {
                "@type": "Organization",
                "name": "Destinyshapers",
                "logo": {
                  "@type": "ImageObject",
                  "url": "https://destinyshapers.co.zw/static/media/destinyshaperscombined-bg.42d7dfa535bfb534b3aa.png"
                }
              },
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "${window.location.href}"
              }
            }
          `}
        </script>
      </Helmet>
      <Box sx={{ bgcolor: 'background.default', padding: 3, marginTop: '60px' }}>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={8}>
              <Card>
                <CardMedia
                  component="img"
                  height="300"
                  image={blog.thumbnail}
                  alt={blog.title}
                />
                <CardContent>
                  <Box display="flex" alignItems="center" marginBottom={2}>
                    <Avatar src={blog.authorPic} alt={blog.author} />
                    <Box marginLeft={2}>
                      <Typography variant="subtitle2">
                        {blog.author}
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        {blog.date}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      fontSize: {
                        xs: '1.5rem',
                        sm: '1.75rem',
                        md: '2rem',
                        lg: '2.125rem'
                      }
                    }}
                  >
                    {blog.title}
                  </Typography>
                  <Typography variant="body1" sx={{ marginTop: 2 }}>
                    {blog.content}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    color="primary"
                    startIcon={<ArrowBackIcon />}
                    onClick={() => navigate('/blogs')}
                  >
                    All Blogs
                  </Button>
                  <Button
                    size="small"
                    color="primary"
                    startIcon={bookmarkedBlogs.some(item => item.id === blog.id) ? <BookmarkIcon /> : <BookmarkBorderIcon />}
                    onClick={() => handleBookmark(blog)}
                  >
                    {bookmarkedBlogs.some(item => item.id === blog.id) ? 'Bookmarked' : 'Bookmark'}
                  </Button>
                  <IconButton color="primary" onClick={handleShare}>
                    <ShareIcon />
                  </IconButton>
                </CardActions>
              </Card>
            </Grid>
            {bookmarkedBlogs.length > 0 && (
            <Grid item xs={12} lg={4}>
              <Typography variant="h3" gutterBottom>
                Bookmarks
              </Typography>
              <Grid container spacing={3}>
                {bookmarkedBlogs.map(bookmarkedBlog => (
                  <Grid item xs={12} key={bookmarkedBlog.id}>
                    <Card>
                      <CardContent>
                        <Typography variant="h6" component="div">
                          {bookmarkedBlog.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ marginTop: 1 }}>
                          {bookmarkedBlog.snippet}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            )}
          </Grid>
        </Container>
      </Box>
    </HelmetProvider>
  );
}

export default BlogPostPage;
