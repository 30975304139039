import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Card, CardContent, CardMedia, CardActions, Button, Grid, Avatar, IconButton, Box } from '@mui/material';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import blogs from "../components/functions/blogs";

function BlogPage() {
  const navigate = useNavigate();
  const [bookmarkedBlogs, setBookmarkedBlogs] = useState([]);

  useEffect(() => {
    const savedBookmarks = JSON.parse(localStorage.getItem('bookmarkedBlogs')) || [];
    setBookmarkedBlogs(savedBookmarks);
  }, []);

  useEffect(() => {
    localStorage.setItem('bookmarkedBlogs', JSON.stringify(bookmarkedBlogs));
  }, [bookmarkedBlogs]);

  const handleBookmark = (blog) => {
    setBookmarkedBlogs(prev => {
      const isBookmarked = prev.some(item => item.id === blog.id);
      if (isBookmarked) {
        return prev.filter(item => item.id !== blog.id);
      } else {
        return [blog, ...prev];
      }
    });
  };

  const handleReadMore = (blog) => {
    navigate(`/blogs/${blog.slug}`);
  };

  return (
    <Box sx={{ bgcolor: 'background.default', padding: 3, marginTop:'67px' }}>
      <Container>
        <Typography variant="h3" gutterBottom sx={{textAlign:'center'}}>
          Blogs
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={8}>
            <Grid container spacing={3}>
              {blogs.map(blog => (
                <Grid item xs={12} md={6} key={blog.id}>
                  <Card>
                    <CardMedia
                      component="img"
                      height="140"
                      image={blog.thumbnail}
                      alt={blog.title}
                    />
                    <CardContent>
                      <Box display="flex" alignItems="center" marginBottom={2}>
                        <Avatar src={blog.authorPic} alt={blog.author} />
                        <Box marginLeft={2}>
                          <Typography variant="subtitle2">
                            {blog.author}
                          </Typography>
                          <Typography variant="caption" color="text.secondary">
                            {blog.date}
                          </Typography>
                        </Box>
                      </Box>
                      <Typography variant="h5" component="div">
                        {blog.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" sx={{ marginTop: 1 }}>
                        {blog.snippet}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button size="small" color="primary" onClick={() => handleReadMore(blog)}>
                        Read More
                      </Button>
                      <IconButton onClick={() => handleBookmark(blog)}>
                        {bookmarkedBlogs.some(item => item.id === blog.id) ? <BookmarkIcon color="primary" /> : <BookmarkBorderIcon />}
                      </IconButton>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
          {bookmarkedBlogs.length > 0 && (
          <Grid item xs={12} lg={4}>
            <Typography variant="h3" gutterBottom>
              Bookmarks
            </Typography>
            <Grid container spacing={3}>
              {bookmarkedBlogs.map(blog => (
                <Grid item xs={12} key={blog.id}>
                  <Card onClick={()=> handleReadMore(blog)}>
                    <CardContent>
                      <Typography variant="h6" component="div">
                        {blog.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" sx={{ marginTop: 1 }}>
                        {blog.snippet}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
          )}
        </Grid>
      </Container>
    </Box>
  );
}

export default BlogPage;
