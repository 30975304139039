import React, { useState, useRef } from 'react';
import { Container, Typography, Card, CardContent, Grid, TextField, Box } from '@mui/material';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import episodes from '../components/functions/episodes';

function PodcastPage() {
  const [searchQuery, setSearchQuery] = useState('');
  const [currentAudio, setCurrentAudio] = useState(null);

  const audioRefs = useRef([]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handlePlay = (index) => {
    if (currentAudio !== null && currentAudio !== index) {
      audioRefs.current[currentAudio].audio.current.pause();
    }
    setCurrentAudio(index);
  };

  const handlePause = () => {
    setCurrentAudio(null);
  };

  const filteredEpisodes = episodes.filter((episode) =>
    episode.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    episode.description.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Box textAlign='center' sx={{ bgcolor: 'background.default', padding: 3, marginTop:'67px'}}>
      <Container>
        <Typography variant="h3" gutterBottom width='100%'sx={{fontSize:{xs:'32px'}}}>
          Podcast Episodes
        </Typography>
        <TextField
          label="Search Podcast"
          variant="outlined"
          fullWidth
          margin="normal"
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <Box sx={{ marginTop: 3 }}>
          <Grid container spacing={3}>
            {filteredEpisodes.map((episode, index) => (
              <Grid item xs={12} md={6} lg={6} key={index}>
                <Card>
                  <CardContent>
                    <img className='episode-thumbnail' src={episode.thumbnail} alt="audio thumbnail" />
                    <Typography variant="h5" component="div">
                      {episode.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ marginBottom: '8px' }}>
                      {episode.description}
                    </Typography>
                    <AudioPlayer
                      ref={(element) => { audioRefs.current[index] = element; }}
                      src={episode.audioSrc}
                      onPlay={() => handlePlay(index)}
                      onPause={handlePause}
                      onEnded={handlePause}
                      customAdditionalControls={[]}
                      customVolumeControls={[]}
                    />
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default PodcastPage;
