import React from "react"
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

export default function SignUpButton() { 
    return (
        <form>
            <Stack
                direction={{ xs: 'column', sm: 'column' }}
                alignSelf="center"
                spacing={1}
                useFlexGap
                sx={{ pt: 2, width: { xs: '100%', sm: 'auto' } }}
            >
              <Link to='/register' style={{ textDecoration: 'none', width: '100%' }}>
                <Button 
                  variant="contained" 
                  color="primary"
                  fullWidth  
                  sx={{ width: '100%' }}  
                >
                    Start now
                </Button>
              </Link>
            </Stack>
        </form>
    )
}