import React, { useState, useEffect, useCallback } from 'react';
import supabase from '../components/supabaseClient';
import {
  Container,
  Typography,
  Grid,
  Paper,
  Button,
  IconButton,
  TextField,
  List,
  ListItem,
  ListItemText,
  Stack,
  Avatar,
  ListItemAvatar,
  Snackbar,
  Alert,
  Checkbox,
  FormControlLabel,
  Modal,
  Box,
  Slider,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { alpha, styled } from '@mui/system';
import { Helmet } from 'react-helmet-async';
import CommentIcon from '@mui/icons-material/Comment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import LockScreen from '../components/LockScreen';
import background from '../assets/paper-back.jpg';

const ScreenshotOverlay = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.1)', 
  zIndex: 1000, 
  pointerEvents: 'none',
  mixBlendMode: 'difference', 
});

const ReadPageContainer = styled(Container)({
  paddingTop: '2rem',
  paddingBottom: '2rem',
  position: 'relative', 
});

const Watermark = styled('div')({
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%) rotate(-45deg)',
  fontSize: '4rem', 
  color: 'rgba(255, 255, 255, 0.15)', 
  zIndex: 999, 
  pointerEvents: 'none', 
  userSelect: 'none', 
});

const BookContentContainer = styled('div')({
  position: 'relative', 
});

const BookContent = styled(Paper)({
  padding: '2rem',
  marginBottom: '1rem',
  backgroundImage: `url(${background})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  color: 'black',
  height: '60vh', 
  overflowY: 'auto',
  fontFamily: "'Merriweather', serif",
  fontSize: '16px',
  lineHeight: '1.6',
  userSelect: 'none', 
  webkitUserSelect: 'none', 
  mozUserSelect: 'none', 
  msUserSelect: 'none', 
  onContextMenu: 'return false;', 
  '& p': {
    marginBottom: '1em',
  },
});

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const ReadPage = () => {
  const [bookContent, setBookContent] = useState('');
  const [chapter, setChapter] = useState('');
  const [fontSize, setFontSize] = useState(16);
  const [title, setTitle] = useState('');
  const [page, setPage] = useState(() => {
    const savedPage = localStorage.getItem('lastReadPage');
    return savedPage ? parseInt(savedPage, 10) : 4;
  });
  const [comment, setComment] = useState('');
  const [comments, setComments] = useState([]);
  const [like, setLike] = useState(false);
  const [likes, setLikes] = useState(false);
  const [user, setUser] = useState(null); 
  const [isUnlocked, setIsUnlocked] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const [replies, setReplies] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedCommentId, setSelectedCommentId] = useState(null);
  const [replyContent, setReplyContent] = useState('n');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  useEffect(() => {
    const lastReadPage = parseInt(localStorage.getItem('lastReadPage'), 10);
  
    if (lastReadPage && lastReadPage !== 4) {
      setPage(lastReadPage);
      setSnackbarMessage(`Welcome back! You can continue reading from page ${lastReadPage} where you left.`);
      setSnackbarSeverity('info');
      setSnackbarOpen(true);
    }
  }, []);
  

  const handleUnlock = () => {
    setIsUnlocked(true);
  };

  function timeAgo(date) {
    const now = new Date();
    const seconds = Math.floor((now - date) / 1000);
  
    const intervals = {
      year: 31536000,
      month: 2592000,
      week: 604800,
      day: 86400,
      hour: 3600,
      minute: 60,
      second: 1,
    };
  
    for (const [unit, value] of Object.entries(intervals)) {
      const count = Math.floor(seconds / value);
      if (count > 0) {
        return count === 1 ? `${count} ${unit} ago` : `${count} ${unit}s ago`;
      }
    }
  
    return 'just now';
  }

  const fetchComments = useCallback(async () => {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) return;
    setUser(user);

    let query = supabase
      .from('comments')
      .select(`
        id,
        comment,
        created_at,
        username,
        user_id,
        public,
        has_replies
      `)
      .eq('page', page)
      .or(`public.eq.true,user_id.eq.${user?.id || ''}`);
  
    const { data, error } = await query;
  
    if (error) {
      console.error('Error fetching comments:', error);
    } else {
      setComments(data);
    }
  }, [page]);
  

  const fetchLike = useCallback(async () => {
    const { count, error } = await supabase
      .from('likes')
      .select('*', { count: 'exact' }) 
      .eq('page', page)
      .eq('liked', true);

    if (error) {
      console.error('Error fetching like status:', error);
    } else {
      setLikes(count);
    }
  }, [page]);

  const fetchReplies = async (commentId) => {
    const { data, error } = await supabase
      .from('comment_replies')
      .select(`
        id,
        reply,
        created_at,
        user_id,
        username
      `)
      .eq('comment_id', commentId);
  
    if (error) {
      console.error('Error fetching replies:', error);
    } else {
      setReplies(data);
    }
  };
  

  useEffect(() => {
    const fetchContent = async () => {
      const { data, error } = await supabase
        .from('book')
        .select('content, chapter, title')
        .eq('page', page)
        .single();

      if (error) console.error('Error fetching content:', error);
      else setBookContent(data.content);
      setChapter(data.chapter);
      setTitle(data.title);
    };

    fetchContent();
    fetchComments();
    fetchLike();

    document.addEventListener('contextmenu', (e) => e.preventDefault());
    document.addEventListener('keydown', (e) => {
      if (e.ctrlKey && ['c', 'p', 's', 'u'].indexOf(e.key.toLowerCase()) !== -1) {
        e.preventDefault();
      }
    });
  }, [page, fetchComments, fetchLike]);

  useEffect(() => {
    localStorage.setItem('lastReadPage', page);
  }, [page]);

  const handleCommentSubmit = async () => {
    const { data: { user }, error: userError } = await supabase.auth.getUser();
  
    if (userError || !user) {
      setSnackbarMessage('You must be logged in to comment.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }
  
    const username = user.user_metadata?.username || 'Anonymous';
    const userId = user.id;
  
    const { error } = await supabase
      .from('comments')
      .insert([{ page, comment, user_id: userId, username, public: !isPublic }]);
  
    if (error) {
      console.error('Error submitting comment:', error);
      setSnackbarMessage('Failed to submit comment.');
      setSnackbarSeverity('error');
    } else {
      setComment('');
      setSnackbarMessage('Comment submitted successfully!');
      setSnackbarSeverity('success');
    }
  
    setSnackbarOpen(true);
    fetchComments(); 
  };

  const handleLike = async () => {
    const { data: { user }, error: userError } = await supabase.auth.getUser();

    if (userError || !user) {
      setSnackbarMessage('You must be logged in to like.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    const { error } = await supabase
      .from('likes')
      .upsert(
        [{ page, liked: !like, user_id: user.id }],
        { onConflict: ['user_id', 'page'] }  
      );

    if (error) {
      console.error('Error liking page:', error);
      setSnackbarMessage('Failed to update like status.');
      setSnackbarSeverity('error');
    } else {
      setLike(!like);
      setSnackbarMessage(like ? 'Unliked successfully!' : 'Liked successfully!');
      setSnackbarSeverity('success');
    }

    setSnackbarOpen(true);
    fetchLike();
  };

  const handleDeleteComment = async (commentId) => {
    const { error } = await supabase
      .from('comments')
      .delete()
      .eq('id', commentId)
      .eq('user_id', user.id); 
  
    if (error) {
      console.error('Error deleting comment:', error);
      setSnackbarMessage('Failed to delete comment.');
      setSnackbarSeverity('error');
    } else {
      setSnackbarMessage('Comment deleted successfully!');
      setSnackbarSeverity('success');
      fetchComments();
    }
  
    setSnackbarOpen(true);
  };

  const handleAddReply = async (commentId, reply) => {
    const { data: { user } } = await supabase.auth.getUser();

    if (!user) {
        setSnackbarMessage('You must be logged in to reply.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        return;
    }

    const { error } = await supabase
        .from('comment_replies')
        .insert([{ comment_id: commentId, reply, user_id: user.id, username: user.user_metadata.username }]);

    if (error) {
        console.error('Error adding reply:', error);
        setSnackbarMessage('Failed to add reply.');
        setSnackbarSeverity('error');
    } else {
        const { data: commentData, error: commentError } = await supabase
            .from('comments')
            .select('user_id')
            .eq('id', commentId)
            .single();

        if (commentError) {
            console.error('Error fetching comment:', commentError);
        } else {
            await supabase
                .from('notifications')
                .insert([
                    {
                        user_id: commentData.user_id,
                        type: 'reply',
                        message: `Someone replied to your comment: ${reply}`
                    }
                ]);
        }

        setSnackbarMessage('Reply added successfully!');
        setSnackbarSeverity('success');
        fetchReplies(commentId);
    }

    setReplyContent('');
    setSnackbarOpen(true);
};

  

  const handleOpenRepliesModal = (commentId) => {
    setSelectedCommentId(commentId);
    fetchReplies(commentId);
    setOpenModal(true);
  };
  
  const handleCloseRepliesModal = () => {
    setOpenModal(false);
  };
  
  const handleDeleteReply = async (replyId) => {
    const { error } = await supabase
      .from('comment_replies')
      .delete()
      .eq('id', replyId);
  
    if (error) {
      console.error('Error deleting reply:', error);
      setSnackbarMessage('Failed to delete reply.');
      setSnackbarSeverity('error');
    } else {
      setSnackbarMessage('Reply deleted successfully!');
      setSnackbarSeverity('success');
      fetchReplies(selectedCommentId);
    }
  
    setSnackbarOpen(true);
  };
  

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const VictorianTypography = styled(Typography)({
    fontFamily: '"Playfair Display", serif',
    fontWeight: 'bold',
  });

  return (
    <>
      {isUnlocked ? (
        <ReadPageContainer
          sx={(theme) => ({
            width: '100%',
            backgroundImage:
              theme.palette.mode === 'light'
                ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
                : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
            backgroundSize: '100% 20%',
            backgroundRepeat: 'no-repeat',
          })}
        >
          <Helmet>
            <title>Read Book - Destinyshapers</title>
            <meta name="description" content="Read the book on Destinyshapers" />
          </Helmet>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <IconButton color="primary" href="/">
                <ArrowBackIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
                <VictorianTypography variant="h4" align="center" gutterBottom>
                  📚 Reading Room
                </VictorianTypography>
                <VictorianTypography variant="h5" align="center" gutterBottom>
                 📖 {chapter}
                </VictorianTypography>
                <Typography sx={{ fontWeight: 'bold' }} align="center" gutterBottom>{title}</Typography>
            </Grid>
            <Grid item xs={12}>

            <BookContentContainer>
              <ScreenshotOverlay />
              <Watermark>Destinyshapers</Watermark>
              <BookContent>
                {bookContent.split('\r\n').map((paragraph, index) => (
                <Typography 
                  key={index} 
                  variant="body1" 
                  sx={{
                    width: '100%',
                    maxWidth: '100%',
                    padding: 0,
                    margin: 0,
                    boxSizing: 'border-box',
                    marginBottom: 1,
                    textAlign: 'justify',
                    fontSize: `${fontSize}px`
                  }}
                >
                  {paragraph}
                </Typography> 
                ))}
              </BookContent>
            </BookContentContainer>
            </Grid>
              <Slider
                value={fontSize}
                onChange={(e, newValue) => setFontSize(newValue)}
                min={12}
                max={21}
                aria-labelledby="font-size-slider"
                sx={{ marginLeft: 2, width: '100%' }}
                valueLabelDisplay="auto"
              />
            <Grid item xs={12}>
              <Stack direction="row" spacing={2} justifyContent="space-evenly">
                <Button
                  variant="outlined"
                  onClick={() => setPage((prev) => (prev > 4 ? prev - 1 : prev))}
                >
                  Previous
                </Button>
                <VictorianTypography style={{transform: 'translate(5%, -17%)'}} variant="h4" color='primary'>
                  {page}
                </VictorianTypography>
                <Button
                  variant="outlined"
                  onClick={() => setPage((prev) => (prev < 87 ? prev + 1 : prev))}
                >
                  Next
                </Button>
                <Button variant="outlined" onClick={handleLike} style={{ padding: "6px 0.5rem" }}>
                  {like ? <ThumbUpIcon /> : <ThumbUpOffAltIcon />}
                  <p style={{ marginLeft: "0.5rem" }}>{like ? 'Unlike' : 'Like'}</p>
                  
                  <p style={{ marginLeft: "0.5rem" }}>({likes})</p>
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder="Add a comment"
                variant="outlined"
                multiline
                rows={2}
              />

              <Button
                variant="outlined"
                sx={{ marginTop: '1rem' }}
                startIcon={<CommentIcon />}
                onClick={handleCommentSubmit}
              >
                Submit
              </Button>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isPublic}
                    onChange={(e) => setIsPublic(e.target.checked)}
                    color="primary"
                  />
                }
                label="Hide"
                sx={{marginTop:'16px', marginLeft:'16px'}}
              />
            </Grid>
            <Grid item xs={12}>
                <VictorianTypography variant="h4" align='center' gutterBottom>
                  Comments ({comments.length})
                </VictorianTypography>
                <List sx={{ maxWidth: '600px', margin: '0 auto', backgroundColor: '#f9f6f1', borderRadius: '8px', padding: '1rem', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
                  {comments.map((c) => (
                    <ListItem
                      key={c.id}
                      alignItems="flex-start"
                      sx={{
                        padding: '1rem 0',
                        borderBottom: '1px solid #dcdcdc',
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar sx={{ bgcolor: '#8b4513', color: '#fff' }}>
                          {c.username.charAt(0).toUpperCase()}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText 
                        secondary={
                          <>
                            <Typography component="span" variant="body1" sx={{ fontFamily: 'Rantser', fontWeight: 'bold', color: '#3e2723' }}>
                              {c.username}
                            </Typography>
                              <Button variant="text" onClick={() => handleOpenRepliesModal(c.id)}>
                                {c.has_replies ? 'See Replies' : 'Reply'}
                              </Button>
                          </>
                        }
                        primary={
                          <>
                            <Typography component="p" variant="body1" sx={{ backgroundColor: c.user_id === user?.id ? '#e0f7fa' : 'inherit', fontFamily: 'Rantser', fontSize: '1rem', color: '#4e342e' }}>
                              {c.comment}
                            </Typography>
                            <Typography component="span" variant="body2" sx={{ display: 'block', fontFamily: 'Rantser', color: '#5d4037', marginBottom: '0.5rem' }}>
                              {timeAgo(new Date(c.created_at))}
                            </Typography>
                          </>
                        }
                      />
                      {c.user_id === user?.id && ( 
                        <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteComment(c.id)}>
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </ListItem>
                  ))}
                </List>
                <Modal
                  open={openModal}
                  onClose={handleCloseRepliesModal}
                  aria-labelledby="reply-modal-title"
                  aria-describedby="reply-modal-description"
                >
                  <Box sx={{ ...modalStyle }}>
                    <Typography id="reply-modal-title" variant="h6" component="h2" sx={{ textAlign: 'center', fontWeight:'400px' }}>
                      Replies
                    </Typography>
                    <List>
                      {replies.map((r) => (
                        <ListItem key={r.id} alignItems="flex-start">
                          <ListItemText
                            primary={r.reply}
                            secondary={`${r.username} • ${timeAgo(new Date(r.created_at))}`}
                          />
                          {r.user_id === user?.id && ( 
                            <IconButton onClick={() => handleDeleteReply(r.id)}><DeleteIcon/></IconButton>
                          )}
                        </ListItem>
                      ))}
                    </List>
                    <TextField
                      fullWidth
                      placeholder="Add a reply"
                      variant="outlined"
                      multiline
                      sx={{marginBottom:'8px'}}
                      rows={2}
                      value={replyContent}
                      onChange={(e) => setReplyContent(e.target.value)}
                    />
                    <Button variant="contained" onClick={() => handleAddReply(selectedCommentId, replyContent)}>
                      Reply
                    </Button>
                  </Box>
                </Modal>
            </Grid>
          </Grid>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </ReadPageContainer>
      ) : (
        <LockScreen onUnlock={handleUnlock} />
      )}
    </>
  );
};

export default ReadPage;
