import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import supabase from '../components/supabaseClient';
import { TextField, Button, Box, Typography, Snackbar, Alert } from '@mui/material';

export default function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'info' });
  const [showForm, setShowForm] = useState(true);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    const { error, data: { session, user } } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error) {
      console.error('Login error:', error.message);
      setShowForm(false);
      setNotification({ open: true, message: `Login failed. ${error.message}`, severity: 'error' });
      return;
    }

    if (session && user) {
      setNotification({ open: true, message: 'Login successful!', severity: 'success' });
      setTimeout(() => navigate('/'), 2000); // Redirect after 2 seconds
    }
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <Box 
      sx={{
        margin: '2rem',
        paddingTop: '3rem',
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <Snackbar 
        open={notification.open} 
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ width: '100%' }}
      >
        <Alert 
          onClose={handleCloseNotification} 
          severity={notification.severity} 
          sx={{ width: '100%' }}
          variant="filled"
        >
          {notification.message}
        </Alert>
      </Snackbar>

      {showForm ? (
        <Box component="form" onSubmit={handleLogin} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Typography variant="h4" style={{ textAlign: 'center', marginBottom: '1rem' }}>Login</Typography>
          <TextField
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <TextField
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
            <Button type="submit" variant="contained" color="primary" sx={{ marginTop: '1rem', padding: '0 3rem' }}>Login</Button>
            <Button onClick={() => navigate('/register')} variant="outlined" color="primary" sx={{ marginTop: '1rem', padding: '0 3rem' }}>Register</Button>
          </Box>
          <Button onClick={() => navigate('/forgot-password')} variant="text" color="primary" sx={{ marginTop: '1rem' }}>Forgot Password?</Button>
        </Box>
      ) : (
        <Box sx={{ textAlign: 'center' }}>
          <Button 
            variant="outlined" 
            color="primary" 
            onClick={() => window.location.reload()}
            sx={{ mt: 2 }}
          >
            Try again
          </Button>
        </Box>
      )}
    </Box>
  );
}
