import React, { useEffect, useState } from 'react';
import { IconButton, Badge, Modal, List, ListItem, ListItemText } from '@mui/material';
import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import supabase from './supabaseClient';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import LoginIcon from '@mui/icons-material/Login';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import ToggleColorMode from './ToggleColorMode';
import logoImage from '../assets/destinyshaperscombined-bg-sameline.png';
import logoImageDark from '../assets/destinyshaperscombined-bg-sameline-dark.png';
import LoginRegisterPopup from './LoginRegisterPop';
import styled from '@emotion/styled';
import NotificationsIcon from '@mui/icons-material/Notifications';

const logoStyle = {
  width: '140px',
  height: 'auto',
  cursor: 'pointer',
};

const StatusDot = styled('div')(({ online }) => ({
  width: '10px',
  height: '10px',
  borderRadius: '50%',
  backgroundColor: online ? 'green' : 'red',
  marginLeft: '8px',
}));

function AppAppBar({ mode, toggleColorMode }) {
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [popupOpen, setPopupOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  function timeAgo(date) {
    const now = new Date();
    const seconds = Math.floor((now - date) / 1000);
  
    const intervals = {
      year: 31536000,
      month: 2592000,
      week: 604800,
      day: 86400,
      hour: 3600,
      minute: 60,
      second: 1,
    };
  
    for (const [unit, value] of Object.entries(intervals)) {
      const count = Math.floor(seconds / value);
      if (count > 0) {
        return count === 1 ? `${count} ${unit} ago` : `${count} ${unit}s ago`;
      }
    }
  
    return 'just now';
  }

  useEffect(() => {
    const fetchNotifications = async () => {
        const { data: { user } } = await supabase.auth.getUser();
        if (user) {
            const { data, error } = await supabase
                .from('notifications')
                .select('*')
                .eq('user_id', user.id)
                .eq('read', false)
                .order('created_at', { ascending: false });

            if (!error) {
                setNotifications(data);
            }
        }
    };

    fetchNotifications();
  }, [user]);

  const handleNotificationClick = async () => {
    setNotificationOpen(true);
  
    const { data: { user } } = await supabase.auth.getUser();
    if (user) {
      const { data: fetchedNotifications, error: fetchError } = await supabase
        .from('notifications')
        .select('*')
        .eq('user_id', user.id);
  
      if (fetchError) {
        console.error('Error fetching notifications:', fetchError);
        return;
      }
  
      setNotifications(fetchedNotifications.map(n => ({ ...n })));
    }
  };

  const markAsRead = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    if (user) {
        await supabase
            .from('notifications')
            .update({ read: true })
            .eq('user_id', user.id)
            .eq('read', false);
        setNotifications([]);
    }
  };

  const handleClose = () => {
    setNotificationOpen(false);
    markAsRead();
  };


  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleLogout = async () => {
    await supabase.auth.signOut();
    setUser(null);
    navigate('/');
  };

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: 'smooth' });
      window.scrollTo({
        top: targetScroll,
        behavior: 'smooth',
      });
      setOpen(false);
    }
  };

  const isHomePage = location.pathname === '/';

  useEffect(() => {
    const getUser = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      setUser(user);
    };
    getUser();
    setOpen(false);
  }, [location]);

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 0,
          bgcolor: 'transparent',
          backgroundImage: 'none',
          mt: 2,
        }}
      >
        <Container maxWidth="lg">
          <Toolbar
            variant="regular"
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexShrink: 0,
              borderRadius: '999px',
              bgcolor:
                theme.palette.mode === 'light'
                  ? 'rgba(255, 255, 255, 0.4)'
                  : 'rgba(0, 0, 0, 0.4)',
              backdropFilter: 'blur(24px)',
              maxHeight: 40,
              border: '1px solid',
              borderColor: 'divider',
              boxShadow:
                theme.palette.mode === 'light'
                  ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                  : '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',
            })}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                ml: '-12px',
                px: 0,
              }}
            >
              <Link to='/'>
                {mode === 'light' ?
                  <img src={logoImage} style={logoStyle} alt="logo for destiny shapers" />
                  :
                  <img src={logoImageDark} style={logoStyle} alt="logo for destiny shapers" />
                }
              </Link>
              {isHomePage && (
                <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                  <MenuItem onClick={() => scrollToSection('testimonials')} sx={{ py: '6px', px: '12px' }}>
                    <Typography variant="body2" color="text.primary">Testimonials</Typography>
                  </MenuItem>
                  <MenuItem onClick={() => scrollToSection('highlights')} sx={{ py: '6px', px: '12px' }}>
                    <Typography variant="body2" color="text.primary">Highlights</Typography>
                  </MenuItem>
                  <MenuItem onClick={() => scrollToSection('faq')} sx={{ py: '6px', px: '12px' }}>
                    <Typography variant="body2" color="text.primary">FAQ</Typography>
                  </MenuItem>
                </Box>
              )}
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 0.5, alignItems: 'center' }}>
            <StatusDot online={isOnline} />
            {user && (
            <IconButton onClick={handleNotificationClick}>
                <Badge badgeContent={notifications.length} color="primary">
                    <NotificationsIcon />
                </Badge>
            </IconButton>
            )}
              <Link to='/buy' style={{ textDecoration: 'none' }}>
                <MenuItem sx={{ py: '6px', px: '24px' }}>
                  <Typography variant="body2" color="text.primary">Buy</Typography>
                </MenuItem>
              </Link>
              <Link to='/reviews' style={{ textDecoration: 'none' }}>
                <MenuItem sx={{ py: '6px', px: '24px' }}>
                  <Typography variant="body2" color="text.primary">Reviews</Typography>
                </MenuItem>
              </Link>
              <Link to='/reading-room' style={{ textDecoration: 'none' }}>
                <MenuItem sx={{ py: '6px', px: '24px' }}>
                  <Typography variant="body2" color="text.primary">Read</Typography>
                </MenuItem>
              </Link>
              <Link style={{ textDecoration: 'none', opacity: user ? 1 : 0.5  }} onClick={user ? undefined : () => setPopupOpen(true)} to={user ? '/podcasts' : '#'}>
                <MenuItem sx={{ py: '6px', px: '24px' }}>
                  <Typography variant="body2" color="text.primary">Podcast</Typography>
                </MenuItem>
              </Link>
              <Link style={{ textDecoration: 'none', opacity: user ? 1 : 0.5  }} onClick={user ? undefined : () => setPopupOpen(true)} to={user ? '/blogs' : '#'}>
                <MenuItem sx={{ py: '6px', px: '24px' }}>
                  <Typography variant="body2" color="text.primary">Blog</Typography>
                </MenuItem>
              </Link>
              
              {user ? (
                <Box>
                <Typography variant="body2" color="text.secondary">{user.user_metadata.username}</Typography>
                <Typography color="text.secondary" onClick={handleLogout} style={{ textAlign: 'right', cursor:'pointer' }}>Logout</Typography>
                </Box>
              ) : (
                <>
                  <Link to='/register' style={{ textDecoration: 'none' }}>
                    <Button color="inherit">Register</Button>
                  </Link>
                  <Link to='/login' style={{ textDecoration: 'none' }}>
                    <Button color="inherit">Login</Button>
                  </Link>
                </>
              )}
              <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} />
            </Box>
            <Box sx={{ display: { sm: '', md: 'none', display: 'flex' }, alignItems: 'center' }}>
            <StatusDot online={isOnline} />
            {user && (
            <IconButton onClick={handleNotificationClick}>
                <Badge badgeContent={notifications.length} color="primary">
                    <NotificationsIcon />
                </Badge>
            </IconButton>
            )}
            <Typography sx={{color:'text.secondary', margin:'0 8px', fontWeight:'600', paddingTop:'4px'}}>{user && user.user_metadata && user.user_metadata.username ? user.user_metadata.username : <a style={{ textDecoration: 'none', color: 'inherit' }} href='/login'><LoginIcon /></a>}</Typography>
              <Button variant="text" color="primary" aria-label="menu" onClick={toggleDrawer(true)} sx={{ minWidth: '30px', p: '4px' }}>
                <MenuIcon />
              </Button>
              <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                <Box sx={{ minWidth: '60dvw', p: 2, backgroundColor: 'background.paper', flexGrow: 1 }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end', flexGrow: 1 }}>
                    <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} />
                  </Box>
                  <Link style={{ textDecoration: 'none' }} to='/'>
                    <MenuItem>Home</MenuItem>
                  </Link>
                  {isHomePage && (
                    <>
                      <MenuItem onClick={() => scrollToSection('testimonials')}>Testimonials</MenuItem>
                      <MenuItem onClick={() => scrollToSection('highlights')}>Highlights</MenuItem>
                      <MenuItem onClick={() => scrollToSection('faq')}>FAQ</MenuItem>
                    </>
                  )}
                  <Divider />
                  <Link to='/buy' style={{ textDecoration: 'none' }}>
                    <MenuItem>Buy</MenuItem>
                  </Link>
                  <Link to='/reviews' style={{ textDecoration: 'none' }}>
                    <MenuItem>Reviews</MenuItem>
                  </Link>
                  <Link to='/reading-room' style={{ textDecoration: 'none' }}>
                    <MenuItem>Reading Room</MenuItem>
                  </Link>
                  <Link style={{ textDecoration: 'none', opacity: user ? 1 : 0.5  }} onClick={user ? undefined : () => setPopupOpen(true)} to={user ? '/podcasts' : '#'}>
                    <MenuItem>Podcast</MenuItem>
                  </Link>
                  <Link style={{ textDecoration: 'none'}} to='/blogs'>
                    <MenuItem>Blog</MenuItem>
                  </Link>
                  {user ? (
                    <Box style={{display:'flex', flexDirection:'column', marginTop:'24px', alignItems:'flex-end'}}>
                    <Typography variant="body2" color="text.secondary" style={{marginRight:"0.8rem", fontWeight:'600'}}>{user.user_metadata.username}</Typography>
                    <Button color="inherit" onClick={handleLogout}>Logout</Button>
                    </Box>
                  ) : (
                    <>
                      <Link to='/register' style={{ textDecoration: 'none', marginLeft:'8px', marginTop:'16px' }}>
                        <Button color="inherit">Register</Button>
                      </Link>
                      <Link to='/login' style={{ textDecoration: 'none' }}>
                        <Button color="inherit">Login</Button>
                      </Link>
                    </>
                  )}
                </Box>
              </Drawer>
            </Box>
            <Modal open={notificationOpen} onClose={handleClose}>
              <div style={{
                padding: '20px', backgroundColor: 'white', margin: '50px auto',
                maxWidth: '400px', width: '95%', borderRadius: '5px'
              }}>
                <h3 style={{ textAlign: 'center' }}>Notifications</h3>
                <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                  <List sx={{ maxWidth: 360, bgcolor: 'background.paper', borderRadius: 1, boxShadow: 3 }}>
                    {notifications.length > 0 ? (
                      notifications
                        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at)) // Sort by time (latest first)
                        .map((notification, index) => (
                          <ListItem
                            key={index}
                            sx={{
                              bgcolor: notification.read ? '#e0e0e0' : index % 2 === 0 ? '#f5f5f5' : 'white',  // Grey out if read
                              borderRadius: 1,
                              marginBottom: 1,
                              padding: 2,
                              '&:hover': {
                                bgcolor: '#d3d3d3',
                              },
                            }}
                          >
                            <ListItemText
                              primary={<span dangerouslySetInnerHTML={{ __html: notification.message }} />}
                              secondary={timeAgo(new Date(notification.created_at))}
                              primaryTypographyProps={{ 
                                fontWeight: 'bold', 
                                color: notification.read ? 'text.secondary' : 'primary.main'  // Lighter color for read notifications
                              }}
                              secondaryTypographyProps={{ color: 'text.secondary', fontStyle: 'italic' }}
                            />
                          </ListItem>
                        ))
                    ) : (
                      <ListItem sx={{ padding: 2, textAlign: 'center' }}>
                        <ListItemText primary="No new notifications." primaryTypographyProps={{ color: 'text.secondary' }} />
                      </ListItem>
                    )}
                  </List>
                </div>
              </div>
            </Modal>
          </Toolbar>
        </Container>
      </AppBar>
      <LoginRegisterPopup open={popupOpen} onClose={() => setPopupOpen(false)} />
    </div>
  );
}

AppAppBar.propTypes = {
  mode: PropTypes.oneOf(['dark', 'light']).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
};

export default AppAppBar;
