import React from 'react';
import { Typography, CardMedia } from '@mui/material';
import thumbnail2 from '../assets/blogs/blog4-thumbnail.jpg';
import thumbnail1 from '../assets/blogs/blog4-theme.jpg';

const Blog4 = () => {
  return (
    <Typography variant="body1" component="div" sx={{ marginTop: 2 }}>
      {/* <h3>Love and Business 2</h3> */}
      <p>Whenever we are invited to speak about our businesses in universities or different organisations, most people are intrigued by how we manage to start businesses as boyfriend and girlfriend and how we are still together. In fact, now we are married.</p>
      <CardMedia
        component="img"
        height="200"
        image={thumbnail1}
        alt="Main Image"
        sx={{ marginY: 2 }}
      />
      <p>Some people say they are inspired by this. I presume they also want to start enterprises with their partners. It's not a bad idea but I like how Edith put advantages and disadvantages of businesses and love in her blog. You have to understand the risks and perks.</p>
      <p>In the book <em>Giving Birth To Ideas</em> I paint a picture of what transpired during our courtship years. We dated for 4 years before we got married. 4 years is a long time, a lot can happen or change both in the business and in the relationship.</p>
      
      <p>So here are some foundational questions you need to ask yourself before you engage your partner or friend in your business enterprise:</p>
      <ul>
        <li>Why are you involving your partner in this business? Is there a need in the business that requires a strength that they have?</li>
        <li>What position or responsibility are they going to occupy? You need to have boundaries and a system that will help you maintain order. Most times, one partner might act emotionally or take their relationship position and use it within the business enterprise. Not being able to maintain these boundaries will cause either the business or the relationship to give in.</li>
        <li>Do you work well together? Some people are able to play together but you might be able to work well together.</li>
      </ul>
      
      <CardMedia
        component="img"
        height="200"
        image={thumbnail2}
        alt="Theme Image"
        sx={{ marginY: 2 }}
      />
      
      <p>These are 3 questions to ask yourself, there are plenty more to consider. However, remember that if your partner does not fall positively in any of the questions, it does not mean they are not fit to be your business partner. You need to identify the problem and see how you can work on it or resolve it.</p>
      <p>At times you might desire so bad to work together or be business partners and yet it's still not working. At this point, consider receiving professional help like counseling. Most young African couples might not consider this in their relationship. This might be the ingredient you need in your relationship for your business to thrive.</p>
      <p>Personally I always say this, do not get a Certificate of Corporation together before you get a Marriage Certificate.</p>
      <p>If you built a business together with your partner, the chances of it surviving after you divorce or break up are very slim, especially if it is a startup. So always have that in the back of your mind before you start on a project together.</p>
      <p>For other people it is easier to separate business with personal relationships that way you avoid a lot of headaches. However, is that true?</p>
    </Typography>
  );
}

export default Blog4;
