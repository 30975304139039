import React from 'react';
import { Typography, CardMedia } from '@mui/material';
import thumbnail2 from '../assets/blogs/blog3-thumbnail.jpg';
import thumbnail1 from '../assets/blogs/blog3-theme.jpg';

const Blog3 = () => {
  return (
    <Typography variant="body1" component="div" sx={{ marginTop: 2 }}>
      {/* <h3>Love and Business</h3> */}
      <p>Here are some of the things I learnt in doing business with your partner. I mean, your boyfriend or girlfriend, husband or wife, or even family. There are advantages and disadvantages but I'll put down 3 I think you should be aware of.</p>
      <p>Being aware of these 3 advantages and disadvantages helps you avoid burnout and manage your relationships and business sustainably and soberly. Especially when you are still young.</p>
      
      <CardMedia
        component="img"
        height="200"
        image={thumbnail1}
        alt="Main Image"
        sx={{ marginY: 2 }}
      />
      
      <h4>Advantages</h4>
      <ul>
        <li><em>Trust and Communication:</em> Partnering with someone you trust deeply can enhance communication and decision-making. You already understand each other's strengths and weaknesses, which can streamline business operations.</li>
        <li><em>Shared Vision:</em> Couples often have aligned goals and values, which can lead to a unified vision for the business. This alignment can drive the business forward with a clear, cohesive strategy.</li>
        <li><em>Emotional Support:</em> Running a business can be stressful. Having a partner who understands you personally and professionally can provide invaluable emotional support during tough times.</li>
      </ul>
      
      <CardMedia
        component="img"
        height="200"
        image={thumbnail2}
        alt="Theme Image"
        sx={{ marginY: 2 }}
      />
      
      <h4>Disadvantages</h4>
      <ul>
        <li><em>Strain on Relationship:</em> Mixing business with personal life can blur boundaries, potentially leading to conflicts that affect both your professional and personal relationships.</li>
        <li><em>Decision-Making Conflicts:</em> Differences in business opinions can lead to disagreements. If not managed well, these conflicts can escalate and impact the relationship.</li>
        <li><em>Work-Life Balance:</em> It can be challenging to separate work from personal life, leading to burnout. The constant overlap can make it difficult to switch off and enjoy time together outside of work.</li>
      </ul>
      
      <h4>In Summary</h4>
      <p>While there are clear advantages to doing business with your relationship partner, such as trust and shared goals, the potential for relationship strain and conflicts requires careful consideration and boundaries to ensure both the business and the relationship thrive.</p>
    </Typography>
  );
}

export default Blog3;
