import React from "react";
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';

function DialogContentText() {

  return (
    <DialogContent dividers>
    <Typography gutterBottom variant="h6" fontSize="1rem" fontWeight="bold">
      1. Members
    </Typography>
    <Typography gutterBottom>
    By becoming a member of the Destinyshapers Community, you agree to abide by these terms and conditions. We reserve the right to update these terms from time to time.
    </Typography>
    <Typography gutterBottom variant="h6" fontSize="1rem" fontWeight="bold">
      2. Personal Information Collection
    </Typography>
    <Typography gutterBottom>
    When joining our community, you agree to provide accurate and complete information, including your full name, phone number, and email address. This information will be used to contact you, inform you about upcoming programs, and grant access to member-exclusive privileges, such as discounts on our products or services.
    </Typography>
    <Typography gutterBottom variant="h6" fontSize="1rem" fontWeight="bold">
      3. Personal Information Usage
    </Typography>
    <Typography gutterBottom>
    We are committed to protecting your privacy. The information collected will not be shared with any third-party entities, nor will it be used for other marketing campaigns. Your personal information will only be utilized for Destinyshapers Community purposes.
    </Typography>
    <Typography gutterBottom variant="h6" fontSize="1rem" fontWeight="bold">
      4. Community Guidelines
    </Typography>
    <Typography gutterBottom>
    As a member of the Destinyshapers Community, you agree to adhere to community guidelines, which include treating other members with respect, refraining from engaging in abusive or inappropriate behavior, and protecting the confidentiality of any privileged information shared within the community.
    </Typography>
    <Typography gutterBottom variant="h6" fontSize="1rem" fontWeight="bold">
      5. Limitation of Liability
    </Typography>
    <Typography gutterBottom>
    To the extent permitted by law, Destinyshapers will not be liable for any indirect or consequential loss or damage arising from your participation in the community or use of our services or products.
    </Typography>
    <Typography gutterBottom variant="h6" fontSize="1rem" fontWeight="bold">
      6. Termination of Membership
    </Typography>
    <Typography gutterBottom>
    Destinyshapers reserves the right to terminate your membership at any time if you breach these terms and conditions or engage in activities detrimental to the community's interests.
    </Typography>
    <Typography gutterBottom variant="h6" fontSize="1rem" fontWeight="bold">
      7. Governing Law and Jurisdiction
    </Typography>
    <Typography gutterBottom>
    These terms and conditions are governed by the laws of Zimbabwe. Any disputes arising from your membership in the Destinyshapers Community shall be resolved in accordance with the laws of Zimbabwe, and the courts of Zimbabwe shall have exclusive jurisdiction to settle any such disputes.
    </Typography>
    <Typography gutterBottom>
    <br/>By joining the Destinyshapers Community, you acknowledge that you have read and understood these terms and conditions. If you have any questions or concerns, please contact us through 
    <Link href={`mailto:support@destinyshapers.co.zw`}> our support mail </Link> or 
    <Link href={`tel:+263 77 979 1000`}> give us a call</Link>.
    </Typography>
  </DialogContent>
  )
}

export default DialogContentText;