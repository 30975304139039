import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { alpha, styled, IconButton } from '@mui/material';
import supabase from './supabaseClient';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from './DialogContentText';
import givingBirthBook from '../assets/books-bg.png';
import Slider from 'react-slick';
// import snippet1 from '../assets/snippets/snippet1.mp4';
import snippet2 from '../assets/snippets/snippet2.mp4';
import snippet3 from '../assets/snippets/snippet3.mp4';
import snippet4 from '../assets/snippets/snippet4.mp4';
import announcement1 from '../assets/announcements/twitterspace.jpg';
import announcement2 from '../assets/announcements/announcement2.PNG';
import SignUpButton from './SignUpButton';
import InviteForm from './InviteForm';
import Carousel from 'react-material-ui-carousel';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import LinkIcon from '@mui/icons-material/Link';

const snippets = [
  // snippet1,
  snippet2,
  snippet3,
  snippet4
]

// BootstrapDialog styling
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

// Carousel settings
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  autoplay: false,
  pauseOnHover: true,
};

export default function Hero() {
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [clickCount, setClickCount] = useState(0);
  const location = useLocation();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const twitterLink = 'https://twitter.com/i/spaces/1zqKVYkMgppxB';
  const googleCalendarLink = `https://calendar.google.com/calendar/u/0/r/eventedit?text=Youth+Engagement+in+Climate+Change+Management+and+Urban+Agriculture+in+Zimbabwe&dates=20240917T190000Z/20240917T203000Z&details=Join+our+Twitter+Space+to+discuss+the+Climate+Change+Management+Draft+Bill%0A%0AJoin+here:+https://twitter.com/i/spaces/1zqKVYkMgppxB&location=Twitter+Space&sf=true`;

  const handleButtonClick = async (boxId) => {

      const newCount = clickCount + 1;
      const { error } = await supabase
        .from('click_counts')
        .update({ count: newCount })
        .eq('box_id', boxId);

      if (!error) {
        setClickCount(newCount); 
      }

  };

  useEffect(() => {
    const fetchClickCount = async () => {
      const { data, error } = await supabase
        .from('click_counts')
        .select('count')
        .eq('box_id', 1) 
        .single();

      if (!error && data) {
        setClickCount(data.count);
      }
    };

    fetchClickCount();
  }, []);

  const handleReminderClick = (boxId) => {
    handleButtonClick(boxId);
    window.open(googleCalendarLink, '_blank');
  };

  const handleTwitterClick = (boxId) => {
    handleButtonClick(boxId);
    window.open(twitterLink, '_blank');
  };

  useEffect(() => {
    const getUser = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      setUser(user);
    };
    getUser();
    setOpen(false);
  }, [location]);

  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundImage:
          theme.palette.mode === 'light'
            ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
            : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 15 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '80%' } }}>
          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box
              sx={{
                width: '100%',
                maxWidth: '550px',
                textAlign: 'center',
                background: 'linear-gradient(45deg, #3a7bd5, #3a6073)', 
                color: 'white',
                py: 1, 
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)', 
                borderRadius: '8px',
                mb: 4, 
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontSize: 'clamp(2rem, 4vw, 3.5rem)', 
                  textTransform: 'uppercase',
                  letterSpacing: '2px',
                }}
              >
                Upcoming Event
              </Typography>
            </Box>
            <Carousel sx={{ width: '100%' }}>
              <Box sx={{ position: 'relative', textAlign: 'center' }}>
                <Box component="img" src={announcement1} alt="Event Poster" sx={{ display: 'block', width: '100%', maxWidth: '500px', borderRadius: 2, mx: 'auto' }} />
                <IconButton
                  sx={{
                    position: 'absolute',
                    top: 12,
                    right: 89,
                    bgcolor: 'rgba(0, 0, 0, 0.2)',
                    '& .MuiSvgIcon-root': {
                      color: '#cd7f32',  // Set the icon color to gold
                      textShadow: '0px 0px 4px rgba(0, 0, 0, 0.5)', // Apply shadow
                    },
                  }}
                  onClick={(e) => { e.stopPropagation(); handleReminderClick(1); }}
                >
                  <AddAlertIcon />
                </IconButton>

                <IconButton
                  sx={{
                    position: 'absolute',
                    top: 12,
                    right: 47,
                    bgcolor: 'rgba(0, 0, 0, 0.2)',
                    '& .MuiSvgIcon-root': {
                      color: '#1e90ff',  // Set the icon color to gold
                      textShadow: '0px 0px 4px rgba(0, 0, 0, 0.5)', // Apply shadow
                    },
                  }}
                  onClick={() => handleTwitterClick(1)} 
                >
                  <LinkIcon />
                </IconButton>
                <Typography variant="caption" sx={{ position: 'absolute', bottom: 16, right: 16, bgcolor: 'rgba(0, 0, 0, 0.6)', color: 'white', padding: '4px 8px', borderRadius: '4px' }}>
                  Clicks: {clickCount}
                </Typography>
              </Box>

              <Box sx={{ position: 'relative', textAlign: 'center' }}>
                <Box component="img" src={announcement2} alt="Event Poster" sx={{ display: 'block', width: '100%', maxWidth: '500px', borderRadius: 2, mx: 'auto' }} />
              </Box>
            </Carousel>
          </Box>
          { !user ? 
          <>
            <Typography
              variant="h1"
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                alignSelf: 'center',
                textAlign: 'center',
                fontSize: 'clamp(2rem, 10vw, 4rem)',
              }}
            >
              Destinyshapers Africa&nbsp;
            </Typography>
            <Typography
              component="span"
              variant="h1"
              sx={{
                fontSize: 'clamp(1rem, 10vw, 2.5rem)',
                alignSelf: 'center',
                textAlign: 'center',
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
              }}
            >
              A World Class Personal Growth Platform
            </Typography>
            <Typography
              textAlign="justify"
              color="text.secondary"
              sx={{ alignSelf: 'center', width: { sm: '100%', md: '90%' }, pt: 2 }}
            >
              All of the material and programs you will find on this platform are specifically designed to help you unlock your full potential and take control of your destiny.
              We are here to help you become the very best version of yourself. One that is not only wealthier or healthier but more fulfilled.<br /><br />
              Founded by Lovemore M Sibanda, a young Zimbabwean agripreneur that built his businesses from ground up with his wife Edith Sibanda without any high tertiary education background or any assistance from financial institutions. Lovemore and Edith are ordinary young African citizens that were able to own their destinies and change their life story.<br /><br />
              Lovemore and Edith have managed to share practical and tried insights from their life story and also connect with and feature well renowned leaders to help many more young people shape their own destinies.
            </Typography>
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignSelf: 'center',
              textAlign: 'center',
              fontSize: 'clamp(3rem, 10vw, 3.5rem)',
              pt: { xs: 3, sm: 12 },
            }}
          >
            Become a&nbsp;
            <Typography
              component="span"
              variant="h1"
              sx={{
                fontSize: 'clamp(2rem, 10vw, 3.5rem)',
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
              }}
            >
              Member
            </Typography>
          </Typography>
          <Typography
            textAlign="justify"
            color="text.secondary"
            sx={{ alignSelf: 'center', width: { sm: '100%', md: '80%' } }}
          >
            Enhance your journey with Destinyshapers by joining our thriving community! Register for free now to unlock exclusive benefits and elevate your experience like never before. Don't miss the opportunity to be part of something extraordinary – start today!
          </Typography>
          <SignUpButton />
          <Typography variant="caption" textAlign="center" sx={{ opacity: 0.8 }}>
            By clicking &quot;Start now&quot; you agree to our&nbsp;
            <Link href="#" color="primary" onClick={handleClickOpen}>
              Terms & Conditions
            </Link>
            .
          </Typography>
          <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              Destinyshapers Community Terms & Conditions
            </DialogTitle>
            <DialogContentText />
            <DialogActions>
              <Button autoFocus onClick={handleClose}>
                I Understand
              </Button>
            </DialogActions>
          </BootstrapDialog>
          </>
          :
          <>
          <Typography
          variant="h1"
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignSelf: 'center',
            textAlign: 'center',
            fontSize: 'clamp(3rem, 10vw, 3.5rem)',
            pt: { xs: 3, sm: 12 },
          }}
        >
          {/* Destinyshapers Africa&nbsp; */}
          <Typography
            component="span"
            variant="h1"
            sx={{
              fontSize: 'clamp(2rem, 10vw, 3.5rem)',
              color: (theme) =>
                theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
            }}
          >
            Welcome {user.user_metadata.username}!
          </Typography>
        </Typography>
        <Typography
            textAlign="justify"
            color="text.secondary"
            sx={{ alignSelf: 'center', width: { sm: '100%', md: '90%' }, pt: 2 }}
          >
            Welcome to our online platform, where you can access a wide range of materials and interact with other members of the community. While some exclusive features are reserved for our subscribers, such as access to our online book, we invite you to explore everything else that our platform has to offer. We encourage you to engage with us and your fellow Destinyshapers as we network on this platform, sharing ideas and supporting each other on our journey. Enjoy your browsing, and don't hesitate to reach out with any questions or suggestions.
          </Typography>
          <Typography
            textAlign="justify"
            color="text.secondary"
            sx={{ alignSelf: 'center', width: { sm: '100%', md: '90%' }, pt: 2 }}
          >
            If you know someone who might benefit from the valuable insights and resources offered by this platform, consider extending a helping hand by sharing their email address with us.
          </Typography>
          <InviteForm user={user} />
          </>
          }
        </Stack>
        <Box
          id="image"
          sx={(theme) => ({
            mt: { xs: 8, sm: 10 },
            alignSelf: 'center',
            height: { xs: 300, sm: 700 },
            width: '100%',
            backgroundImage: `url(${givingBirthBook})`,
            backgroundSize: 'cover',
            borderRadius: '10px',
            outline: '1px solid',
            outlineColor:
              theme.palette.mode === 'light'
                ? alpha('#BFCCD9', 0.5)
                : alpha('#9CCCFC', 0.1),
            boxShadow:
              theme.palette.mode === 'light'
                ? `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`
                : `0 0 24px 12px ${alpha('#033363', 0.2)}`,
            position: 'relative',
          })}
        >
          <Box
            sx={{
              backgroundColor: alpha('#000', 0.8),
              borderRadius: '10px',
            }}
          >
            <Slider {...settings}>
              {snippets.map((snippet, index) => (
                <Box key={index} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', maxHeight: '100%', borderRadius: '10px' }}>
                  <video
                    id='video-snippets'
                    src={snippet}
                    controls
                    style={{ width: '100%',objectFit: 'cover', borderRadius: '10px'}}
                    controlsList="nodownload"
                  />
                </Box>
              ))}
            </Slider>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
