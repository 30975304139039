import Lovemore from "../../assets/blogs/lovemore.png";
import Edith from "../../assets/blogs/edith.jpg";
import thumbnail1 from "../../assets/blogs/blog1-thumbnail.jpg";
import thumbnail2 from "../../assets/blogs/blog2-thumbnail.jpg";
import thumbnail3 from "../../assets/blogs/blog3-theme.jpg";
import thumbnail4 from "../../assets/blogs/blog4-theme.jpg";
import thumbnail5 from "../../assets/blogs/blog5-thumbnail.jpg";
import thumbnail6 from "../../assets/blogs/blog6-theme.jpg";
import lastThumbnail1 from "../../assets/blogs/blog1-thumbnail3.jpg";
import lastThumbnail2 from "../../assets/blogs/blog2-thumbnail3.jpg";
import lastThumbnail3 from "../../assets/blogs/blog3-thumbnail3.jpg";
import lastThumbnail4 from "../../assets/blogs/blog4-thumbnail4.jpg";
import lastThumbnail5 from "../../assets/blogs/blog5-thumbnail5.jpg";
import lastThumbnail6 from "../../assets/blogs/blog6-thumbnail6.jpg";
import Blog1Content from "../Blog1";
import Blog2Content from "../Blog2";
import Blog3Content from "../Blog3";
import Blog4Content from "../Blog4";
import Blog5Content from "../Blog5";
import Blog6Content from "../Blog6";

function generateSlug(title) {
  return title
    .toLowerCase()
    .replace(/[^a-z0-9\s-]/g, '') 
    .trim()
    .replace(/\s+/g, '-') 
    .replace(/-+/g, '-'); 
}

const blogs = [
    {
      id: 1,
      title: "The Journey from Idea to Reality: A Tale of Two Stages",
      slug: generateSlug("The Journey from Idea to Reality: A Tale of Two Stages"),
      author: "Lovemore M. Sibanda",
      date: "May 30, 2024",
      snippet: "Imagine you have a seed. This seed is special because it's the beginning of something wonderful, like a beautiful flower or a big tree. In the world of ideas, we also have something like seeds. We call these seeds...",
      content: <Blog1Content/>,
      thumbnail: thumbnail1,
      lastThumbnail: lastThumbnail1,
      authorPic: Lovemore
    },
    {
      id: 2,
      title: "Demystifying Entrepreneurship in Africa",
      slug: generateSlug("Demystifying Entrepreneurship in Africa"),
      author: "Lovemore M. Sibanda",
      date: "June 11, 2024",
      snippet: "Entrepreneurship in Africa is often shrouded in misconceptions and myths. To truly understand the entrepreneurial landscape of this vibrant continent, it's essential to debunk these myths and highlight the...",
      content: <Blog2Content/>,
      thumbnail: thumbnail2,
      lastThumbnail: lastThumbnail2,
      authorPic: Lovemore
    },
    {
      id: 3,
      title: "Love and Business Part 1",
      slug: generateSlug("Love and Business Part 1"),
      author: "Edith Sibanda",
      date: "June 16, 2024",
      snippet: "Here are some of the things I learnt in doing business with your partner. I mean, your boyfriend or girlfriend, husband or wife, or even family. There are advantages and disadvantages...",
      content: <Blog3Content/>,
      thumbnail: thumbnail3,
      lastThumbnail: lastThumbnail3,
      authorPic: Edith
    },
    {
      id: 4,
      title: "Love and Business Part 2",
      slug: generateSlug("Love and Business Part 2"),
      author: "Lovemore M. Sibanda",
      date: "June 24, 2024",
      snippet: "Whenever we are invited to speak about our businesses in universities or different organisations, most people are intrigued by how we manage to start businesses as boyfriend and girlfriend...",
      content: <Blog4Content/>,
      thumbnail: thumbnail4,
      lastThumbnail: lastThumbnail4,
      authorPic: Lovemore
    },
    {
      id: 5,
      title: "Discovering the Hidden You: A Journey to Self-Exploration and Fulfillment",
      slug: generateSlug("Discovering the Hidden You"),
      author: "Lovemore M. Sibanda",
      date: "July 14, 2024",
      snippet: "Have you ever paused to truly ask yourself, 'Who am I?' In a world overflowing with distractions, do you know...",
      content: <Blog5Content/>,
      thumbnail: thumbnail5,
      lastThumbnail: lastThumbnail5,
      authorPic: Lovemore
    },
    {
      id: 6,
      title: "Birthing Your True Self: The Power of Your Inner Thoughts",
      slug: generateSlug("Birthing Your True Self"),
      author: "Lovemore M. Sibanda",
      date: "August 1, 2024",
      snippet: "You are an idea of yourself. You are the first and most significant idea that you are giving birth to: Yourself.",
      content: <Blog6Content/>,
      thumbnail: thumbnail6,
      lastThumbnail: lastThumbnail6,
      authorPic: Lovemore
    },
];

export default blogs;
