import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
// import { useTheme } from '@mui/system';
import Testimony1 from '../assets/wilsa3.webm';
import Testimony2 from '../assets/RuvimboMatipano.mp4';

const userTestimonials = [
  {
    avatar: <Avatar alt="John Dzawanda" src="/static/images/avatar/1.jpg" />,
    name: 'John Dzawanda',
    occupation: '',
    testimonial:
      "It has always been my desire to have a copy of this book,  fortunately after an insatiable desire for this masterpiece Mr Omega bought a copy for me, since l had always been telling him that l have so many business idea's which are like a thorn in my flesh l can't even rest because of these ideas but l need a Jumpstart and l am eager to get a copy of your book because you published your book during a moment l was having a series of ideas . However, trust me  you wrote this book for me, considering the timing and the title of the book which is aptly named giving birth to ideas, yet l have always been naively telling Mr Omega that l have ideas but l am looking for a gynecologist who can help me push these ideas otherwise l will either abort or have a miscarriage of these ideas if l don't find one, moreover here  am l with this copy. I guarantee you  based on this book l shall be like an arrow released from the quiver . Thank you so much Sir.",
  },
  {
    avatar: <Avatar alt="Omega Muyambo" src="/static/images/avatar/2.jpg" />,
    name: 'Omega Muyambo',
    occupation: '',
    testimonial:
      "Thank you again for the practical book,what took my mind so far is the faint concept on savings.Before Giving birth to my ideas I need CAPITAL and I have to sacrifice my WANTS most which are social acceptance.",
  },
  {
    avatar: <Avatar alt="John Gushutu" src="/static/images/avatar/2.jpg" />,
    name: 'John Gushutu',
    occupation: '',
    testimonial:
      "It's a great read. It made me realize that most times we think we need big ideas to come up with a business, that's not the case. People have needs that other people are solving everyday, that all to come up with a business. I used to think that the porridge idea was too small until the day I read your book. Then I conducted a survey on Campus and the majority of the students I asked eat porridge especially instant ones.",
  },
  {
    avatar: <Avatar alt="Trish" src="/static/images/avatar/3.jpg" />,
    name: 'Trish',
    occupation: '',
    testimonial: 
      'I have loved the part that says there are parts of your ideas in other people( that means need to collaborate or to be mentored)  and still the ideas remains mine.',
  },
  {
    avatar: <Avatar alt="Wilsa" src="/static/images/avatar/4.jpg" />,
    name: 'Wilsa',
    occupation: 'Indonesian Entreprenuer',
    testimonial: Testimony1,
  },
  {
    avatar: <Avatar alt="Ruvimbo" src="/static/images/avatar/5.jpg" />,
    name: 'Ruvimbo Matipano',
    occupation: 'Zimbabwean Entreprenuer',
    testimonial: Testimony2,
  },
];

// const whiteLogos = [
//   // 'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560628e8573c43893fe0ace_Sydney-white.svg',
//   // 'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f4d520d0517ae8e8ddf13_Bern-white.svg',
//   // 'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f46794c159024c1af6d44_Montreal-white.svg',
//   // 'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/61f12e891fa22f89efd7477a_TerraLight.svg',
//   // 'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560a09d1f6337b1dfed14ab_colorado-white.svg',
//   // 'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f5caa77bf7d69fb78792e_Ankara-white.svg',
// ];

// const darkLogos = [
//   // 'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560628889c3bdf1129952dc_Sydney-black.svg',
//   // 'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f4d4d8b829a89976a419c_Bern-black.svg',
//   // 'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f467502f091ccb929529d_Montreal-black.svg',
//   // 'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/61f12e911fa22f2203d7514c_TerraDark.svg',
//   // 'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560a0990f3717787fd49245_colorado-black.svg',
//   // 'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f5ca4e548b0deb1041c33_Ankara-black.svg',
// ];

// const logoStyle = {
//   width: '64px',
//   opacity: 0.3,
// };

export default function Testimonials() {
  // const theme = useTheme();
  // const logos = theme.palette.mode === 'light' ? darkLogos : whiteLogos;

  const videoRef = useRef(null);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setIsVideoLoaded(true);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    const currentVideoRef = videoRef.current;
    if (currentVideoRef) {
      observer.observe(currentVideoRef);
    }

    return () => {
      if (currentVideoRef) {
        observer.unobserve(currentVideoRef);
      }
    };
  }, []);

  return (
    <Container
      id="testimonials"
      sx={{
        pt: { xs: 1, sm: 1 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { xs: 'center', sm: 'center', md: 'center' },
        }}
      >
        <Typography component="h2" variant="h4" color="text.primary">
          Testimonials
        </Typography>
        <Typography variant="body1" color="text.secondary" textAlign='justify'>
          See what our customers love about our book! Discover how we excel in
          delivering life-changing information with an agenda to shape the destiny of Africans.
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {userTestimonials.map((testimonial, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: 'flex' }}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flexGrow: 1,
                p: 1,
              }}
            >
              <CardContent>
                <Typography variant="body2" color="text.secondary" component="div">
                  {testimonial.testimonial.at(0) === '/' ? (
                    <div ref={videoRef}>
                      {isVideoLoaded && (
                        <video controls style={{ width: '100%', maxHeight: '270px', borderRadius: '10px' }}>
                          <source src={testimonial.testimonial} type="video/webm" />
                          <source src={testimonial.testimonial} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      )}
                    </div>
                  ) : (
                    testimonial.testimonial
                  )}
                </Typography>
              </CardContent>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  pr: 2,
                }}
              >
                <CardHeader
                  avatar={testimonial.avatar}
                  title={testimonial.name}
                  subheader={testimonial.occupation}
                />
                {/* <img
                  src={logos[index]}
                  alt={`Logo ${index + 1}`}
                  style={logoStyle}
                /> */}
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
