import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography, Grid, Card, CardContent, Avatar, IconButton, Snackbar } from '@mui/material';
import Rating from '@mui/material/Rating';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import supabase from '../components/supabaseClient'; 
import givingBirthBook from '../assets/books-bg.png';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MuiAlert from '@mui/material/Alert';

const BookReviewPage = () => {
  const [user, setUser] = useState(null);
  const [rating, setRating] = useState(0);
  const [reviewText, setReviewText] = useState('');
  const [reviews, setReviews] = useState([]);
  const [bookId] = useState(1); 
  const [editMode, setEditMode] = useState(false);
  const [editReviewId, setEditReviewId] = useState(null);
  const [editRating, setEditRating] = useState(0);
  const [editReviewText, setEditReviewText] = useState('');
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarSeverity, setSnackBarSeverity] = useState('success');


  useEffect(() => {
    const fetchUser = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      setUser(user);
    };
    fetchUser();
  }, []);

  useEffect(() => {
    const fetchReviews = async () => {
      const { data, error } = await supabase
        .from('reviews')
        .select('id, user_id, rating, review_text, created_at, username, review_likes(user_id)')
        .eq('book_id', bookId);

      if (error) {
        console.error('Error fetching reviews:', error);
      } else {
        setReviews(data);
      }
    };

    fetchReviews();
  }, [bookId]);

  const handleSnackBarClose = () => {
    setSnackBarOpen(false);
  };

  const handlePostReview = async () => {
    if (!user) {
      setSnackBarMessage("You need to be logged in to edit a review.");
      setRating(0);
      setSnackBarSeverity('error')
      setReviewText('');
      setSnackBarOpen(true);
      return;
    }

    if (rating < 1) {
      setSnackBarSeverity('error')
      setSnackBarMessage("Please provide a rating of at least 1 star.");
      setSnackBarOpen(true);
      return;
    }

    const existingReview = reviews.find(review => review.user_id === user.id);

    if (existingReview) {
      setSnackBarSeverity('error')
      setSnackBarMessage("You have already posted a review for this book.");
      setSnackBarOpen(true);
      setRating(0);
      setReviewText('');
      return;
    }

    const { error } = await supabase
      .from('reviews')
      .insert([{ user_id: user.id, book_id: bookId, rating, review_text: reviewText, username:user.user_metadata.username }]);

    if (error) {
      console.error('Error posting review:', error);
    } else {
      setRating(0);
      setReviewText('');
      setSnackBarSeverity('success');
      setSnackBarMessage("Review posted successfully.");
      setSnackBarOpen(true);
      const fetchReviews = async () => {
        const { data, error } = await supabase
          .from('reviews')
          .select('id, user_id, rating, review_text, created_at, username, review_likes(user_id)')
          .eq('book_id', bookId);

        if (error) {
          console.error('Error fetching reviews:', error);
        } else {
          setReviews(data);
        }
      };

      fetchReviews();
    }
  };


  const handleLike = async (reviewId) => {
    if (!user) {
      setSnackBarSeverity('error')
      setSnackBarMessage("You need to be logged in to like a review.");
      setSnackBarOpen(true);
      return;
    }

    const { data, error } = await supabase
      .from('review_likes')
      .select('id')
      .eq('review_id', reviewId)
      .eq('user_id', user.id)
      .single();

    if (error && error.code !== 'PGRST116') { // PGRST116 indicates no match was found
      console.error('Error checking like status:', error);
    } else if (data) {
      // User has already liked the review, so we remove the like
      const { error: unlikeError } = await supabase
        .from('review_likes')
        .delete()
        .eq('id', data.id);

      if (unlikeError) {
        console.error('Error unliking review:', unlikeError);
        setSnackBarSeverity('error')
        setSnackBarMessage('Error unliking review:', unlikeError);
        setSnackBarOpen(true);
      } else {
        const fetchReviews = async () => {
          const { data, error } = await supabase
            .from('reviews')
            .select('id, user_id, rating, review_text, created_at, username, review_likes(user_id)')
            .eq('book_id', bookId);
  
          if (error) {
            console.error('Error fetching reviews:', error);
          } else {
            setReviews(data);
          }
        };
        fetchReviews();
        setSnackBarSeverity('info')
        setSnackBarMessage("Review unliked.");
        setSnackBarOpen(true);
      }
    } else {
      // User hasn't liked the review, so we add a like
      const { error: likeError } = await supabase
        .from('review_likes')
        .insert([{ review_id: reviewId, user_id: user.id }]);

      if (likeError) {
        console.error('Error liking review:', likeError);
      } else {
        const fetchReviews = async () => {
          const { data, error } = await supabase
            .from('reviews')
            .select('id, user_id, rating, review_text, created_at, username, review_likes(user_id)')
            .eq('book_id', bookId);
  
          if (error) {
            console.error('Error fetching reviews:', error);
          } else {
            setReviews(data);
          }
        };
        fetchReviews();
        setSnackBarSeverity('success');
        setSnackBarMessage("Review liked.");
        setSnackBarOpen(true);
      }
    }
  };

  const handleDeleteReview = async (reviewId) => {
    if (!user) {
      setSnackBarSeverity('error')
      setSnackBarMessage("You need to be logged in to delete a review.");
      setSnackBarOpen(true);
      return;
    }
  
    const { error } = await supabase
      .from('reviews')
      .delete()
      .eq('id', reviewId)
      .eq('user_id', user.id);
  
    if (error) {
      console.error('Error deleting review:', error);
    } else {
      const { data, error: fetchError } = await supabase
        .from('reviews')
        .select('id, user_id, rating, review_text, created_at, username')
        .eq('book_id', bookId);
  
      if (fetchError) {
        console.error('Error fetching reviews:', fetchError);
      } else {
        setSnackBarSeverity('info');
        setSnackBarMessage("Your review has been deleted successfully.");
        setSnackBarOpen(true);
        setReviews(data);
      }
    }
  };
  
  const handleEditReview = async () => {
  
    const { error } = await supabase
      .from('reviews')
      .update({ rating: editRating, review_text: editReviewText })
      .eq('id', editReviewId)
      .eq('user_id', user.id);
  
    if (error) {
      console.error('Error updating review:', error);
    } else {
      const { data, error: fetchError } = await supabase
        .from('reviews')
        .select('id, user_id, rating, review_text, created_at, username, review_likes(user_id)')
        .eq('book_id', bookId);
  
      if (fetchError) {
        console.error('Error fetching reviews:', fetchError);
      } else {
        setReviews(data);
        setEditMode(false);
        setEditReviewId(null);
        setEditRating(0);
        setEditReviewText('');
        setSnackBarSeverity('success');
        setSnackBarMessage("Your review has been updated successfully.");
        setSnackBarOpen(true);
      }
    }
  };
  

  return (
    <Box sx={{ padding: 3, marginTop: '60px' }}>
      <Typography variant="h3" gutterBottom style={{textAlign:'center'}}>
        Book Reviews ({reviews.length})
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box sx={{ textAlign: 'center' }}>
            <img src={givingBirthBook} alt="Book Title" style={{ width: '50%', marginBottom: '20px' }} />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h5">Post Your Review</Typography>
              <Rating
                value={rating}
                onChange={(event, newValue) => setRating(newValue)}
                precision={0.5}
                sx={{ marginBottom: 2 }}
              />
              <TextField
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                placeholder="Write your review..."
                value={reviewText}
                onChange={(e) => setReviewText(e.target.value)}
              />
              <Button
                variant="contained"
                color="primary"
                sx={{ marginTop: 2 }}
                onClick={handlePostReview}
                // disabled={!user}
              >
                Post Review
              </Button>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          {/* Reviews Section */}
          {reviews.length > 0 ? (
              reviews.map((review) => (
                <Card key={review.id} sx={{ marginTop: 2 }}>
                  <CardContent>
                    <Box display="flex" alignItems="center">
                      <Avatar sx={{ marginRight: 2 }}>{review.username[0]}</Avatar>
                      <Box>
                        <Typography variant="h6">{review.username}</Typography>
                        <Typography variant="caption" color="text.secondary">
                          {new Date(review.created_at).toLocaleString()}
                        </Typography>
                      </Box>
                    </Box>
                    {editMode && editReviewId === review.id ? (
                      <>
                        <Rating
                          value={editRating}
                          onChange={(event, newValue) => setEditRating(newValue)}
                          precision={0.5}
                          sx={{ marginTop: 2 }}
                        />
                        <TextField
                          fullWidth
                          multiline
                          rows={4}
                          variant="outlined"
                          placeholder="Edit your review..."
                          value={editReviewText}
                          onChange={(e) => setEditReviewText(e.target.value)}
                          sx={{ marginTop: 2 }}
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{ marginTop: 2 }}
                          onClick={handleEditReview}
                        >
                          Save Changes
                        </Button>
                      </>
                    ) : (
                      <>
                        <Typography variant="body1" sx={{ marginTop: 2 }}>
                          {review.review_text}
                        </Typography>
                        <Rating value={review.rating} readOnly sx={{ marginTop: 1 }} />
                        <Box sx={{ marginTop: 1 }}>
                        <IconButton onClick={() => handleLike(review.id)}>
                            {review.review_likes.some((like) => like.user_id === user?.id) ? (
                              <FavoriteIcon color="primary" />
                            ) : (
                              <FavoriteBorderIcon />
                            )}
                        </IconButton>
                          <Typography variant="body2" sx={{ marginLeft: 1, display: 'inline' }}>
                            {review.review_likes.length}
                          </Typography>
                          {user && review.user_id === user.id && (
                            <>
                              <IconButton onClick={() => setEditMode(true) & setEditReviewId(review.id) & setEditRating(review.rating) & setEditReviewText(review.review_text)}>
                                <EditIcon />
                              </IconButton>
                              <IconButton onClick={() => handleDeleteReview(review.id)}>
                                <DeleteIcon />
                              </IconButton>
                            </>
                          )}
                        </Box>
                      </>
                    )}
                  </CardContent>
                </Card>
              ))
          ) : (
            <Typography variant="body1" sx={{ marginTop: 2 }}>
              No reviews yet. Be the first to post one!
            </Typography>
          )}
        </Grid>
      </Grid>
      <Snackbar open={snackBarOpen} autoHideDuration={6000} onClose={handleSnackBarClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <MuiAlert onClose={handleSnackBarClose} severity={snackBarSeverity} elevation={6} variant="filled">
          {snackBarMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default BookReviewPage;
