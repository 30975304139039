import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import supabase from '../components/supabaseClient';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import {
  Container,
  Box,
  Typography,
  Grid,
  TextField,
  MenuItem,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { alpha, styled } from '@mui/system';
import IconButton from '@mui/material/IconButton';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import ChatIcon from '@mui/icons-material/Chat';
import givingBirthBook from '../assets/books-bg.png';
import ecocashLogo from '../assets/logos/ecocashlogo.png';
import paypalLogo from '../assets/logos/paypallogo.png';
import innbucksLogo from '../assets/logos/innbuckslogo.png';

const tiers = [
  {
    title: 'Soft Copy',
    price: '9.99',
    originalPrice: '13.99',
    description: ['Direct access to reading room', 'Special discount; time limited!'],
    buttonText: 'Buy now',
    buttonVariant: 'contained',
  },
  {
    title: 'Hard Copy',
    price: '19.99',
    description: ['Hard copy', 'Arranged delivery or collection', 'Delivery T & Cs apply'],
    buttonText: 'Buy Now',
    buttonVariant: 'contained',
  },
  {
    title: 'Hybrid',
    subheader: 'Recommended',
    price: '15',
    description: [
      'All in hardcopy & soft copy',
      '+ Signed hard copy',
      '+ Access to premium membership',
      '+ Access to Destiny Shapers Network',
    ],
    buttonText: 'Buy Now',
    buttonVariant: 'contained',
  },
];

const memberTiers = [
  {
    title: 'Soft Copy',
    price: '3',
    description: ['Access to reading room'],
    buttonText: 'Buy now',
    buttonVariant: 'contained',
  },
  {
    title: 'Hard Copy',
    price: '10',
    description: ['Book hard copy', 'Arranged delivery or collection', 'Delivery T & Cs apply'],
    buttonText: 'Buy Now',
    buttonVariant: 'contained',
  },
  {
    title: 'Hybrid',
    subheader: 'Recommended',
    price: '12',
    description: [
      'All in hardcopy & soft copy',
      '+ Signed hard copy',
    ],
    buttonText: 'Buy Now',
    buttonVariant: 'contained',
  },
];

const paymentMethods = [
  { method: 'PayPal', details: 'godlovemore1@gmail.com', logo: paypalLogo },
  { method: 'Ecocash', details: '0772350023 Lovemore Sibanda', logo: ecocashLogo },
  { method: 'Innbucks', details: '0772350023 Lovemore Sibanda', logo: innbucksLogo },
];

const StyledContainer = styled(Container)({
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px',
  color: '#fff',
});

const StyledBox = styled(Box)({
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  borderRadius: '8px',
  padding: '16px',
  color: '#fff',
  border: '1px solid blue',
});

const BuyPage = () => {
  const [isMember, setIsMember] = useState(false);
  const [user, setUser] = useState('');
  const [selectedTier, setSelectedTier] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [userDetails, setUserDetails] = useState({ fullName: '', email: '', phoneNumber: '', memberCode: '' });
  const [showForm, setShowForm] = useState(false);
  const [showPaymentDetails, setShowPaymentDetails] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [formError, setFormError] = useState('');
  const location = useLocation();
  

  const handleMembershipSelection = () => {
    setIsMember((prevIsMember) => !prevIsMember);
    setSelectedTier(null);
    setShowForm(false);
  };

  const handleTierSelection = (tier) => {
    setSelectedTier(tier);
    setShowForm(true);
  };

  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (!isMember || (isMember && userDetails.memberCode === 'DSM-2024-08-198')) {
      if (!validatePhoneNumber(userDetails.phoneNumber)) {
        setFormError('Please enter a valid phone number.');
        return;
      }
      try {
        const response = await fetch('https://formspree.io/f/xzzpbgaq', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(userDetails),
        });
        if (response.ok) {
          setShowForm(false);
          setShowPaymentDetails(true);
        } else {
          setFormError('An error occurred while submitting the form. Enter valid details and check network connectivity then try again.');
        }
      } catch (error) {
        setFormError('An error occurred while submitting the form. Enter valid details and check network connectivity then try again.');
      }
    } else {
      setFormError('Please enter a valid member code. Check your email for this.');
    }
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneNumberPattern = /^\+?\d{5,15}$/;
    return phoneNumberPattern.test(phoneNumber);
  };

  const handlePaymentDone = () => {
    setShowPaymentDetails(false);
    setShowMessage(true);
  };

  const handleFinalMessageClose = () => {
    setShowMessage(false);
    window.location.href = '/';
  };

  useEffect(() => {
    const getUser = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      setUser(user);
      setIsMember(!!user);
    };
    getUser();
  }, [location]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>Buy the Book - Destiny Shapers</title>
        <meta name="description" content="Purchase your copy of 'Giving Birth to Ideas: Journey of a Startup' - available in Soft Copy, Hard Copy, and Hybrid options. Special discounts available for members!" />
        <meta property="og:title" content="Buy the Book - Destiny Shapers" />
        <meta property="og:description" content="Purchase your copy of 'Giving Birth to Ideas: Journey of a Startup' - available in Soft Copy, Hard Copy, and Hybrid options. Special discounts available for members!" />
        <meta property="og:image" content={givingBirthBook} />
        <meta property="og:url" content={`${window.location.origin}/buy`} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Buy the Book - Destiny Shapers" />
        <meta name="twitter:description" content="Purchase your copy of 'Giving Birth to Destiny: Journey of a Startup' - available in Soft Copy, Hard Copy, and Hybrid options. Special discounts available for members!" />
        <meta name="twitter:image" content={givingBirthBook} />
        <link rel="canonical" href={`${window.location.origin}/buy`} />
      </Helmet>
      <StyledContainer
            sx={(theme) => ({
              width: '100%',
              backgroundImage:
                theme.palette.mode === 'light'
                  ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
                  : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
              backgroundSize: '100% 20%',
              backgroundRepeat: 'no-repeat',
            })}
      >
        <Box textAlign="center" sx={{marginTop:'67px'}}>
          <Typography variant="h3" gutterBottom color='text.primary'>
            Get the Book
          </Typography>
          <Box marginBottom={2}>
          <Box marginBottom={2}>
            <Button
              variant="outlined"
              onClick={handleMembershipSelection}
            >
              {isMember ? 'Not a Member' : 'Member'}
            </Button>
          </Box>
          </Box>
          {!isMember && (
          <Typography color='black' variant='body1'>
            <Link to='/register' style={{ textDecoration:'none', cursor: 'pointer', color: 'blue' }}>Become a member</Link> for free now and access special offers and community benefits <br/><br/>
          </Typography>
          )};
          {isMember && (
            user ? (
            <Typography color="primary" variant="body1">
              As a member you're privy to Destinyshapers discounts. Enjoy! <br/><br/>
            </Typography>
            ) : (
            <Typography color="error" variant="body1">
              Kindly be advised that these benefits are enjoyed by members. In the case that you proceed without membership, you'll automatically be mandated to become a member before accesss.<br/><br/>Avoid repeating a payment to access the reading room.<br/><br/>
            </Typography>
            )
          )}
          <Grid container spacing={4}>
            {(isMember ? memberTiers : tiers).map((tier) => (
              <Grid item key={tier.title} xs={12} sm={6} md={4}>
                <StyledBox sx={{  
                  backgroundImage: `url(${givingBirthBook})`,
                  backgroundSize: 'fit',
                  backgroundRepeat: 'no-repeat',
                  }}>
                  <Typography variant="h6" gutterBottom>
                    {tier.title}
                  </Typography>
                  <Typography variant="h4" gutterBottom>
                    ${tier.price}
                  </Typography>
                  <ul>
                    {tier.description.map((line) => (
                      <Typography component="li" key={line} sx={{listStyleType:'none'}}>
                        {line}
                      </Typography>
                    ))}
                  </ul>
                  <Button
                    variant={tier.buttonVariant}
                    onClick={() => handleTierSelection(tier)}
                    fullWidth
                    sx={{fontWeight:'bold'}}
                  >
                    {tier.buttonText}
                  </Button>
                </StyledBox>
              </Grid>
            ))}
          </Grid>

          <Dialog open={showForm} onClose={() => setShowForm(false)}>
            <DialogTitle>Enter Your Details</DialogTitle>
            <form action="https://formspree.io/f/xzzpbgaq" method="POST" onSubmit={handleFormSubmit}>
              <DialogContent>
                {formError && (
                  <Typography color="error" variant="body1">
                    {formError}
                  </Typography>
                )}
                <input
                  type="hidden"
                  name="selectedTier"
                  value={selectedTier ? selectedTier.title : ''}
                />
                <TextField
                  label="Selected Tier"
                  value={selectedTier ? selectedTier.title : ''}
                  fullWidth
                  margin="normal"
                  InputProps={{
                    readOnly: true,
                  }}
                />
                {isMember && (
                  <TextField
                    label="Member Code"
                    name="memberCode"
                    value={userDetails.memberCode}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />
                )}
                <TextField
                  label="Full Name"
                  name="fullName"
                  value={userDetails.fullName}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Email"
                  name="email"
                  value={userDetails.email}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Phone Number"
                  name="phoneNumber"
                  value={userDetails.phoneNumber}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                  error={!validatePhoneNumber(userDetails.phoneNumber) && userDetails.phoneNumber !== ''}
                  helperText={!validatePhoneNumber(userDetails.phoneNumber) && userDetails.phoneNumber !== '' ? 'Please enter a valid phone number.' : ''}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setShowForm(false)} color="primary">
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Submit
                </Button>
              </DialogActions>
            </form>
          </Dialog>

          <Dialog open={showPaymentDetails} onClose={() => setShowPaymentDetails(false)}>
            <DialogTitle>Payment Details</DialogTitle>
            <DialogContent>
              <TextField
                select
                label="Choose Payment Method"
                value={paymentMethod}
                onChange={handlePaymentMethodChange}
                fullWidth
                margin="normal"
              >
                {paymentMethods.map((method) => (
                  <MenuItem key={method.method} value={method.method}>
                    {method.method}
                  </MenuItem>
                ))}
              </TextField>
              {paymentMethod && (
                <Box marginTop={2}>
                  <Typography variant="h6" gutterBottom>
                    Payment Instructions
                  </Typography>
                  {paymentMethods.map(
                    (method) =>
                      method.method === paymentMethod && (
                        <>
                        <img
                          src={method.logo}
                          alt={method.method}
                          className='payment-method-img'
                        />
                        <Typography key={method.method} variant="body1" sx={{textAlign:'center'}}>
                          Send ${selectedTier.price} to "{method.details}" via {method.method} in order to get the {selectedTier.title} tier.
                        </Typography>
                        </>
                      )
                  )}
                </Box>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setShowPaymentDetails(false)} color="primary">
                Cancel
              </Button>
              <Button onClick={handlePaymentDone} color="primary">
                Done
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={showMessage} onClose={handleFinalMessageClose}>
            <DialogTitle>Thank you!</DialogTitle>
            <DialogContent>
              <Typography variant="body1">
                After completing the transaction, send Proof of Payment (POP) to any of the following platforms: <br/><br/>
              </Typography>
              <Typography variant="body1">
            <IconButton 
              component="a" 
              href="https://api.whatsapp.com/send?text=Hello%20Destinyshapers%20sales,%20I%20have%20made%20a%20payment%20and%20would%20like%20to%20arrange%20collection%20or%20download." 
              target='_blank' 
              rel='noreferrer' 
              style={{textDecoration:'none'}}
            >
              <WhatsAppIcon style={{ color: '#25D366' }} />
            </IconButton>
            <a 
              href="https://api.whatsapp.com/send?text=Hello%20Destinyshapers%20sales,%20I%20have%20made%20a%20payment%20and%20would%20like%20to%20arrange%20collection%20or%20download." 
              target='_blank' 
              rel='noreferrer' 
              style={{ textDecoration:'none', color: 'inherit' }}
            >
              +263772350023
            </a>
          </Typography>
          <Typography variant="body1">
            <IconButton 
              component="a" 
              href="mailto:sales@destinyshapers.co.zw?subject=Destinyshapers%20Sales%20Enquiry&body=Hello%20Destinyshapers%20sales,%20I%20have%20made%20a%20payment%20and%20would%20like%20to%20arrange%20collection%20or%20download." 
              target='_blank' 
              rel='noreferrer' 
              style={{ textDecoration:'none' }}
            >
              <EmailIcon style={{ color: '#EA4335' }} />
            </IconButton>
            <a 
              href="mailto:sales@destinyshapers.co.zw?subject=Destinyshapers%20Sales%20Enquiry&body=Hello%20Destinyshapers%20sales,%20I%20have%20made%20a%20payment%20and%20would%20like%20to%20arrange%20collection%20or%20download." 
              target='_blank' 
              rel='noreferrer' 
              style={{ textDecoration:'none', color: 'inherit' }}
            >
              sales@destinyshapers.co.zw
            </a>
          </Typography>
          <Typography variant="body1">
            <IconButton 
              component="a" 
              href="https://www.destinyshapers.co.zw/?start_chat=true" 
              target='_blank' 
              rel='noreferrer' 
              style={{ textDecoration:'none' }}
            >
              <ChatIcon style={{ color: '#00AEEF' }} />
            </IconButton>
            <a 
              href="https://www.destinyshapers.co.zw/?start_chat=true" 
              target='_blank' 
              rel='noreferrer' 
              style={{ textDecoration:'none', color: 'inherit' }}
            >
              Chat agent
            </a>
          </Typography>
              <Typography variant="body1"> <br/>Please note that we will follow up with details provided if you do not initiate a chat with us.</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleFinalMessageClose} color="primary">
                Okay
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </StyledContainer>
    </HelmetProvider>
  );
};

export default BuyPage;
