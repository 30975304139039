import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import supabase from '../components/supabaseClient';
import { TextField, Button, Box, MenuItem, Select, InputLabel, FormControl, Typography, Snackbar, Alert } from '@mui/material';

const countries = [
  "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina", "Armenia", "Australia",
  "Austria", "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", "Benin", "Bhutan",
  "Bolivia", "Bosnia and Herzegovina", "Botswana", "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi", "Cabo Verde", "Cambodia",
  "Cameroon", "Canada", "Central African Republic", "Chad", "Chile", "China", "Colombia", "Comoros", "Congo, Democratic Republic of the",
  "Congo, Republic of the", "Costa Rica", "Cote d'Ivoire", "Croatia", "Cuba", "Cyprus", "Czech Republic", "Denmark", "Djibouti",
  "Dominica", "Dominican Republic", "Ecuador", "Egypt", "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia", "Eswatini",
  "Ethiopia", "Fiji", "Finland", "France", "Gabon", "Gambia", "Georgia", "Germany", "Ghana", "Greece", "Grenada", "Guatemala",
  "Guinea", "Guinea-Bissau", "Guyana", "Haiti", "Honduras", "Hungary", "Iceland", "India", "Indonesia", "Iran", "Iraq", "Ireland",
  "Israel", "Italy", "Jamaica", "Japan", "Jordan", "Kazakhstan", "Kenya", "Kiribati", "Kosovo", "Kuwait", "Kyrgyzstan", "Laos",
  "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania", "Luxembourg", "Madagascar", "Malawi", "Malaysia",
  "Maldives", "Mali", "Malta", "Marshall Islands", "Mauritania", "Mauritius", "Mexico", "Micronesia", "Moldova", "Monaco",
  "Mongolia", "Montenegro", "Morocco", "Mozambique", "Myanmar", "Namibia", "Nauru", "Nepal", "Netherlands", "New Zealand",
  "Nicaragua", "Niger", "Nigeria", "North Korea", "North Macedonia", "Norway", "Oman", "Pakistan", "Palau", "Palestine", "Panama",
  "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Poland", "Portugal", "Qatar", "Romania", "Russia", "Rwanda", "Saint Kitts and Nevis",
  "Saint Lucia", "Saint Vincent and the Grenadines", "Samoa", "San Marino", "Sao Tome and Principe", "Saudi Arabia", "Senegal",
  "Serbia", "Seychelles", "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "Solomon Islands", "Somalia", "South Africa", "South Korea",
  "South Sudan", "Spain", "Sri Lanka", "Sudan", "Suriname", "Sweden", "Switzerland", "Syria", "Taiwan", "Tajikistan", "Tanzania",
  "Thailand", "Timor-Leste", "Togo", "Tonga", "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan", "Tuvalu", "Uganda", "Ukraine",
  "United Arab Emirates", "United Kingdom", "United States", "Uruguay", "Uzbekistan", "Vanuatu", "Vatican City", "Venezuela", "Vietnam",
  "Yemen", "Zambia","Zimbabwe"
];

function SignUpPage() {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [surname, setSurname] = useState('');
  const [country, setCountry] = useState('Zimbabwe');
  const [phone, setPhone] = useState('');
  const [username, setUsername] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [subscribed] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'info' });
  const navigate = useNavigate();

  const resetForm = () => {
    setEmail('');
    setPassword('');
    setFirstName('');
    setSurname('');
    setCountry('');
    setPhone('');
    setUsername('');
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email regex pattern
    return re.test(String(email).toLowerCase());
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    if (validateEmail(value)) {
      setEmailError(""); 
    } else {
      setEmailError("Please enter a valid email address.");
    }
  };

  const handleUsernameChange = (e) => {
    const value = e.target.value;
    setUsername(value);
    

    if (value.length < 3 || value.length > 15) {
        setUsernameError('Username must be between 3 and 15 characters');
    } else {
        setUsernameError('');
    }
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
      return;
    }
    
    const { data, error } = await supabase.auth.signUp({
      email,
      password,
      options: {
        data: {
          firstName,
          surname,
          phone,
          country,
          username,
          subscribed,
        },
      },
    });

    if (error) {
      console.error('Error signing up:', error.message);
      setNotification({ open: true, message: `Error: ${error.message}`, severity: 'error' });
      return;
    }

    if (data) {
      setNotification({ open: true, message: 'Sign up successful! Please check your email to verify your account.', severity: 'success' });
      // setTimeout(() => navigate('/login'), 10000);
      resetForm();
      setShowForm(false);
    }
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <Box sx={{ margin: '2rem', paddingTop: '3rem', display:"flex", justifyContent:'center' }}>
      <Snackbar 
        open={notification.open} 
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ width: '100%' }}
      >
        <Alert 
          onClose={handleCloseNotification} 
          severity={notification.severity} 
          sx={{ width: '100%' }}
          variant="filled"
        >
          {notification.message}
        </Alert>
      </Snackbar>

      {showForm ? (
        <Box component="form" onSubmit={handleSignUp} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Typography variant="h4" gutterBottom style={{ textAlign: 'center' }}>Register</Typography>
          <TextField label="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
          <TextField label="Surname" value={surname} onChange={(e) => setSurname(e.target.value)} required />
          <TextField label="Username" value={username} error={!!usernameError} helperText={usernameError} onChange={handleUsernameChange} required />
          <TextField label="Phone Number" value={phone} onChange={(e) => setPhone(e.target.value)} required />
          <FormControl required>
            <InputLabel>Country</InputLabel>
            <Select value={country} onChange={(e) => setCountry(e.target.value)}>
              {countries.map((country) => (
                <MenuItem key={country} value={country}>{country}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField label="Email" type="email" value={email} error={!!emailError} helperText={emailError} onChange={handleEmailChange} required />
          <TextField label="Password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
            <Button type="submit" variant="contained" color="primary" sx={{ marginTop: '1rem', padding: '0 3rem' }}>Register</Button>
            <Button onClick={() => navigate('/login')} variant="outlined" color="primary" sx={{ marginTop: '1rem', padding: '0 3rem' }}>Login</Button>
          </Box>
        </Box>
      ) : (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h6" gutterBottom>
            Thank you for signing up!
          </Typography>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={() => navigate('/')}
            sx={{ mt: 2 }}
          >
            Go to Home Page
          </Button>
        </Box>
      )}
    </Box>
  );
}

export default SignUpPage;
