import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import CssBaseline from '@mui/material/CssBaseline';
import './App.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import AppAppBar from './components/AppAppBar';
import Hero from './components/Hero';
import Highlights from './components/Highlights';
// import Pricing from './components/Pricing';
import Testimonials from './components/Testimonials';
import FAQ from './components/FAQ';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import getLPTheme from './getLPTheme';
// import GtagHOC from './GTagHOC';
import PodcastsPage from './pages/PodcastsPage';
import PodcastEpisodePage from './pages/PodcastEpisodePage';
import BlogPage from './pages/BlogPage';
import BlogPostPage from './pages/BlogPostPage';
import NotFoundPage from './pages/NotFoundPage';
// import LockScreen from './components/LockScreen';
import BuyPage from './pages/BuyPage';
import FloatingButton from './components/FloatingButton';
import PrivateRoute from './components/PrivateRoute';
import ReadPage from './pages/ReadPage';
import SignUpPage from './pages/SignUpPage';
import LoginPage from './pages/LoginPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import BookReviewPage from './pages/BookReviewPage';

function ToggleCustomTheme({ showCustomTheme, toggleCustomTheme }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100vw',
        position: 'fixed',
        bottom: 24,
      }}
    >
      <ToggleButtonGroup
        color="primary"
        exclusive
        value={showCustomTheme}
        onChange={toggleCustomTheme}
        aria-label="Platform"
        sx={{
          backgroundColor: 'background.default',
          '& .Mui-selected': {
            pointerEvents: 'none',
          },
        }}
      >
        <ToggleButton value sx={{ display: 'none' }}>
          <AutoAwesomeRoundedIcon sx={{ fontSize: '20px', mr: 1 }} />
          Modern Theme
        </ToggleButton>
        <ToggleButton value={false} sx={{ display: 'none' }}>Classic Theme</ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
}

ToggleCustomTheme.propTypes = {
  showCustomTheme: PropTypes.bool.isRequired,
  toggleCustomTheme: PropTypes.func.isRequired,
};

function App() {
  const [mode, setMode] = useState('light');
  const [showCustomTheme, setShowCustomTheme] = useState(true);
  // const [isUnlocked, setIsUnlocked] = useState(false);
  const LPtheme = createTheme(getLPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  const toggleCustomTheme = () => {
    setShowCustomTheme((prev) => !prev);
  };

  const ScrollToSection = () => {
    const location = useLocation();

    useEffect(() => {
      if (location.state?.sectionId) {
        const sectionElement = document.getElementById(location.state.sectionId);
        const offset = 128;
        if (sectionElement) {
          const targetScroll = sectionElement.offsetTop - offset;
          sectionElement.scrollIntoView({ behavior: 'smooth' });
          window.scrollTo({
            top: targetScroll,
            behavior: 'smooth',
          });
        }
      }
    }, [location]);

    return null;
  };

  return (
      <HelmetProvider>
        <Helmet>
          <title>Destinyshapers</title>
          <meta name="description" content="Destinyshapers Africa is a world-class personal growth platform offering tailored programs and resources to help you unlock your potential, take control of your destiny, and become the best version of yourself. Founded by Lovemore and Edith Sibanda, Destinyshapers provides insights from their own inspiring journey to empower and guide others towards a successful, fulfilling life." />
          <meta property="og:title" content="Destinyshapers" />
          <meta property="og:description" content="Destinyshapers Africa is a world-class personal growth platform offering tailored programs and resources to help you unlock your potential, take control of your destiny, and become the best version of yourself. Founded by Lovemore and Edith Sibanda, Destinyshapers provides insights from their own inspiring journey to empower and guide others towards a successful, fulfilling life." />
          <meta property="og:image" content="default-image-url" />
          <meta property="og:url" content={window.location.origin} />
          <meta property="og:type" content="website" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Destinyshapers" />
          <meta name="twitter:description" content="Destinyshapers Africa is a world-class personal growth platform offering tailored programs and resources to help you unlock your potential, take control of your destiny, and become the best version of yourself. Founded by Lovemore and Edith Sibanda, Destinyshapers provides insights from their own inspiring journey to empower and guide others towards a successful, fulfilling life." />
          <meta name="twitter:image" content="default-image-url" />
          <link rel="canonical" href={window.location.origin} />
        </Helmet>
        <ThemeProvider theme={showCustomTheme ? LPtheme : defaultTheme}>
          <CssBaseline />
          {/* {isUnlocked ? ( */}
            <Router>
              <ScrollToTop />
              <ScrollToSection />
              <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
              <Routes>
                <Route
                  path='/'
                  element={
                    <>
                      <Hero />
                      <Box sx={{ bgcolor: 'background.default' }}>
                        {/* <Pricing /> */}
                        {/* <Divider /> */}
                        <Testimonials />
                        <Divider />
                        <Highlights />
                        <Divider />
                        <FAQ />
                        <Divider />
                      </Box>
                    </>
                  }
                />
                <Route path='/buy' element={<BuyPage />} />
                <Route
                  path="/reviews"
                  element={
                      <BookReviewPage />
                  }
                />
                <Route
                  path="/reading-room"
                  element={
                      <ReadPage />
                  }
                />
                <Route
                  path="/blogs"
                  element={
                    // <PrivateRoute>
                      <BlogPage />
                    // </PrivateRoute>
                  }
                />
                <Route
                  path="/podcasts"
                  element={
                    <PrivateRoute>
                      <PodcastsPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/podcasts/:slug"
                  element={
                    <PrivateRoute>
                      <PodcastEpisodePage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/blogs/:slug"
                  element={
                    <PrivateRoute>
                      <BlogPostPage />
                    </PrivateRoute>
                  }
                />
              <Route path="/register" element={<SignUpPage />} />
              <Route path="/login" element={<LoginPage />} /> 
              <Route path="/forgot-password" element={<ForgotPasswordPage />} /> 
              <Route path="/reset-password" element={<ResetPasswordPage />} />                           
                {/* <Route path='/podcasts' element={<PodcastsPage />} />
                <Route path='/blogs' element={<BlogPage />} /> */}
                {/* <Route path='/blogs/:slug' element={<BlogPostPage />} />
                <Route path='/podcasts/:slug' element={<PodcastEpisodePage />} /> */}
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
              <Footer mode={mode} />
              <FloatingButton />
            </Router>
          {/* // ) : ( */}
            {/* // <LockScreen onUnlock={handleUnlock} /> */}
          {/* // )} */}
          <ToggleCustomTheme
            showCustomTheme={showCustomTheme}
            toggleCustomTheme={toggleCustomTheme}
          />
        </ThemeProvider>
      </HelmetProvider>
  );
}

export default App;
