import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import { keyframes } from '@mui/system';

const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
`;

function FloatingButton() {
    const navigate = useNavigate();
    const location = useLocation();

    if (location.pathname === '/buy' || location.pathname === '/reading-room') {
        return null; 
    }

    return (
      <Box
        id="floatingButton"
        sx={{
          position: 'fixed',
          bottom: 24,
          left: 16,
          backgroundColor: 'primary.main',
          color: 'white',
          padding: '12px 12px',
          borderRadius: '8px',
          opacity: 0.8,
          fontWeight: 'bold',
          animation: `${bounce} 4s ease infinite`
        }}
        onClick={() => navigate('/buy')}
      >
        Get Book Now
      </Box>
    );
  }

  export default FloatingButton;