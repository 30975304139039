import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import supabase from '../components/supabaseClient';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function ForgotPasswordPage() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handlePasswordReset = async (e) => {
    e.preventDefault();

    const { error } = await supabase.auth.resetPasswordForEmail(email);

    if (error) {
      setError('Password reset failed. If you do not receive an email within 5 minutes, kindly contact us.');
      console.error('Error:', error.message);
    } else {
      setMessage('Password reset email sent! Please check your inbox.');
    }
  };

  return (
    <Box 
      component="form" 
      onSubmit={handlePasswordReset} 
      sx={{ display: 'flex', flexDirection: 'column', gap: 2, margin: '2rem', paddingTop:'3rem', maxWidth:'400px' }}
    >
      <Typography variant="h4" style={{textAlign:'center',marginBottom:'1rem'}}>Reset Password</Typography>
      <TextField
        label="Email"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <Button type="submit" variant="contained" color="primary" style={{marginTop:'1rem', padding:'0 3rem'}}>Send Reset Link</Button>
      {message && <Typography color="success">{message}</Typography>}
      {error && <Typography color="error">{error}</Typography>}
      <Button onClick={() => navigate('/login')} variant="outlined" color="primary" style={{marginTop:'1rem', padding:'0 3rem'}}>Back to Login</Button>
    </Box>
  );
}
