import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import supabase from '../components/supabaseClient';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function ResetPasswordPage() {
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  // const location = useLocation();

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    
    // const query = new URLSearchParams(location.search);
    // const accessToken = query.get('access_token');

    // if (!accessToken) {
    //   setError('Invalid or expired token.');
    //   return;
    // }

    const { error } = await supabase.auth.updateUser({
      password,
    });

    if (error) {
      setError('Failed to reset password.');
      console.error('Error:', error.message);
    } else {
      setMessage('Password reset successfully.');
      setTimeout(() => navigate('/'), 2000); 
    }
  };

  return (
    <Box 
      component="form" 
      onSubmit={handlePasswordReset} 
      sx={{ display: 'flex', flexDirection: 'column', justifyContent:'center', gap: 2, margin: '2rem', paddingTop:'3rem', maxWidth:'400px' }}
    >
      <Typography variant="h4" style={{textAlign:'center',marginBottom:'1rem'}}>Reset Password</Typography>
      <TextField
        label="New Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
      />
      <Button type="submit" variant="contained" color="primary" style={{marginTop:'1rem', padding:'0 3rem'}}>Reset Password</Button>
      {message && <Typography color="success">{message}</Typography>}
      {error && <Typography color="error">{error}</Typography>}
    </Box>
  );
}
