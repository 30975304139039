import React, { useState } from 'react';
import { Button, TextField, Snackbar } from '@mui/material';

const InviteForm = ({ user }) => {
  const [invitedEmail, setInvitedEmail] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [emailError, setEmailError] = useState(false);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(invitedEmail)) {
      setEmailError(true);
      setSnackbarMessage('Please enter a valid email address.');
      setSnackbarOpen(true);
      return;
    }

    const formData = {
      firstName: user.user_metadata.firstName,
      userEmail: user.email,
      invitedEmail: invitedEmail,
    };

    try {
      const response = await fetch('https://formspree.io/f/xldrnoaw', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSnackbarMessage(`Thank you! Invitation to ${invitedEmail} has been sent successfully!`);
        setInvitedEmail(''); 
      } else {
        setSnackbarMessage('Failed to send the invitation.');
      }
    } catch (error) {
      setSnackbarMessage('An error occurred while sending the invitation.');
    } finally {
      setSnackbarOpen(true);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        label="Invitee's Email"
        fullWidth
        variant="outlined"
        value={invitedEmail}
        onChange={(e) => {
          setInvitedEmail(e.target.value);
          setEmailError(!validateEmail(e.target.value));
        }}
        error={emailError}
        helperText={emailError ? 'Please enter a valid email address.' : ''}
        required
      />
      <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
        Send Invitation
      </Button>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </form>
  );
};

export default InviteForm;
