import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Box, Typography, Button } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 370,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius:'10px',
  boxShadow: 24,
  p: 4,
};

const LoginRegisterPopup = ({ open, onClose }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="login-register-title"
      aria-describedby="login-register-description"
    >
      <Box sx={style}>
        <Typography id="login-register-title" variant="h6" component="h2">
          Login or Register
        </Typography>
        <Typography id="login-register-description" sx={{ mt: 2 }}>
          To access features exclusive to DestinyShapers members, please log in or register.
        </Typography>
        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="contained" color="primary" onClick={() => window.location.href = '/login'}>
            Login
          </Button>
          <Button variant="outlined" color="primary" onClick={() => window.location.href = '/register'}>
            Register
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

LoginRegisterPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default LoginRegisterPopup;