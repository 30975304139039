import React from 'react';
import { Typography, CardMedia } from '@mui/material';
import { Link } from 'react-router-dom';
import thumbnail1 from '../assets/blogs/blog6-thumbnail.jpg';
import thumbnail2 from '../assets/blogs/blog6-theme.jpg';

const Blog6 = () => {
  return (
    <Typography variant="body1" component="div" sx={{ marginTop: 2 }}>
      <h3>Birthing Your True Self: The Power of Your Inner Thoughts</h3>
      <p>You are an idea of yourself. You are the first and most significant idea that you are giving birth to: Yourself.</p>
      <p>What thoughts are you harvesting for you? What thoughts are you thinking about yourself? Each day, the idea of you is generated in your mind, and for years, you have been building the image of you. This ongoing creation is a reflection of your innermost thoughts, beliefs, and perceptions.</p>
      
      <CardMedia
        component="img"
        height="200"
        image={thumbnail1}
        alt="Main Image"
        sx={{ marginY: 2 }}
      />
      
      <p>The idea of you is not defined by what people see or say. The true essence of you resides within. Others merely reciprocate the idea of you that you have birthed. Often, we find ourselves concerned and discouraged by what others think. We feel disheartened when their words seem to belittle us. Yet, they are only reflecting back the image we have projected.</p>
      <p>The inception of the idea of you begins within. It is a seed that can only be found in the depths of your own soul, in the quiet moments of self-reflection, and in the courage to face your true self. You hold the power to shape and nurture this idea, to transform it into a magnificent reality.</p>
      <p>Imagine waking up each day with the realization that you are in control of your narrative. Your thoughts, your beliefs, your vision of who you are and who you can become are entirely within your grasp. The idea of you that you nurture will flourish and manifest in the world around you.</p>
      
      <CardMedia
        component="img"
        height="200"
        image={thumbnail2}
        alt="Theme Image"
        sx={{ marginY: 2 }}
      />
      
      <p>Think about the power of your thoughts. Are they empowering? Are they aligned with the vision of your best self? Are you cultivating thoughts that build you up, that encourage growth, resilience, and confidence? Or are you allowing doubts and fears to dictate the idea of you?</p>
      <p>Now, picture a life where you fully embrace the idea of your true self. A life where your thoughts are your greatest allies, where you nurture and protect the vision of you that you want to bring into the world. This is not a distant dream; it is a journey that begins with a single step—choosing to believe in the idea of you that you wish to become.</p>
      <p>This transformative journey is the heart of my book, <Link to="/buy" style={{textDecoration:'none'}}><em>Giving Birth to Ideas</em></Link>. It's more than just a guide; it's a companion on your path to self-discovery and personal empowerment. Through its pages, you will learn how to cultivate and nurture the ideas that define you, how to overcome the obstacles that hinder your growth, and how to bring forth the most authentic and powerful version of yourself.</p>
      <p>If you feel the stirrings of a desire to explore the depths of your potential, to redefine the idea of you, then <Link to="/buy" style={{textDecoration:'none'}}><em>Giving Birth to Ideas</em></Link> is the key to unlocking that journey. It's time to take control of your narrative, to birth the idea of you that is waiting to shine.</p>
      <p>Are you ready to embark on this journey? To embrace the power within and transform your life? Let <Link to="/buy" style={{textDecoration:'none'}}><em>Giving Birth to Ideas</em></Link> be your guide, and discover the limitless possibilities that lie within you.</p>
      <p>Your friend,</p>
      <p>Lovemore</p>
    </Typography>
  );
}

export default Blog6;
