import React from 'react';
import { Typography, CardMedia } from '@mui/material';
import thumbnail1 from "../assets/blogs/blog1-thumbnail.jpg";
import thumbnail2 from "../assets/blogs/blog1-theme.jpg";

const Blog1 = () => {
  return (
    <Typography variant="body1" component="div" sx={{ marginTop: 2 }}>
      <p>Imagine you have a seed. This seed is special because it's the beginning of something wonderful, like a beautiful flower or a big tree. In the world of ideas, we also have something like seeds. We call these seeds "ideas." But just like real seeds need to be planted and taken care of to grow, ideas need special care too.</p>
      <CardMedia
        component="img"
        height="200"
        image={thumbnail1}
        alt="Main Image"
        sx={{ marginY: 2 }}
      />
      <h4>Conceiving an Idea: The Seed in Your Mind</h4>
      <p>When you conceive an idea, it's like having a seed in your pocket. You think of something cool, like a new game you want to play or a story you want to write. This is an exciting moment! Your mind is buzzing with possibilities, and you feel super creative. Here are some things that happen when you conceive an idea:</p>
      <ul>
        <li><em>Excitement:</em> You feel happy and excited because you have thought of something new.</li>
        <li><em>Possibilities:</em> You can imagine all the amazing things that could happen if your idea comes to life.</li>
      </ul>
      <CardMedia
        component="img"
        height="200"
        image={thumbnail2}
        alt="Farmer Image"
        sx={{ marginY: 2 }}
      />
      <h4>Giving Birth to an Idea: Growing Your Idea</h4>
      <p>Now, let's talk about giving birth to an idea. This is like taking the seed out of your pocket, planting it in the soil, watering it, and watching it grow. When you give birth to an idea, you turn your thoughts into actions. Here’s what happens when you grow your idea:</p>
      <ul>
        <li><em>Creation:</em> Your idea turns into something real. You might make a new game, write a story, or build something amazing.</li>
        <li><em>Pride and Joy:</em> You feel proud and happy because you made something real that others can see and enjoy.</li>
        <li><em>Learning and Growing:</em> You learn a lot as you work on your idea. Even if things don't go perfectly, you get better and better.</li>
      </ul>
      <p>Giving birth to an idea has some big advantages:</p>
      <ul>
        <li><em>Real Results:</em> You see the fruits of your labor. Your idea becomes something you can touch, see, or share.</li>
        <li><em>Impact:</em> Your idea can make a difference. It can make people happy, solve a problem, or make life more fun.</li>
      </ul>
      <h4>The Moral of the Story</h4>
      <p>So, remember, having an idea is just the beginning. It's like having a seed in your pocket. But the magic happens when you plant that seed, take care of it, and watch it grow. Your ideas are powerful, and with a little effort, you can turn them into something amazing that everyone can enjoy.</p>
      <p>So next time you have a cool idea, don't just keep it in your mind. Start working on it and watch your idea come to life!</p>
    </Typography>
  );
}

export default Blog1;
