function generateSlug(title) {
  return title
    .toLowerCase()
    .replace(/[^a-z0-9\s-]/g, '') // Remove special characters
    .trim()
    .replace(/\s+/g, '-') // Replace spaces with hyphens
    .replace(/-+/g, '-'); // Replace multiple hyphens with a single hyphen
}

const episodes = [
    {
      id: 1,
      title: "Episode 1: African Stories",
      slug: generateSlug("Episode 1: African Stories"),
      description: "In this episode, we introduce you to the world of Destinyshapers and what we believe.",
      audioSrc: "/ep1-african_stories.mp3",
      thumbnail: "../books-bg.png"
    }
  ];

export default episodes;