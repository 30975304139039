import React from 'react';
import { Typography, CardMedia } from '@mui/material';
import { Link } from 'react-router-dom';
import thumbnail1 from '../assets/blogs/blog5-thumbnail.jpg';
import thumbnail2 from '../assets/blogs/blog5-thumbnail5.jpg';

const Blog5 = () => {
  return (
    <Typography variant="body1" component="div" sx={{ marginTop: 2 }}>
      <h3>Discovering the Hidden You: A Journey to Self-Exploration and Fulfillment</h3>
      <p>Have you ever paused to truly ask yourself, "Who am I?" In a world overflowing with distractions, do you know how much you truly mean to yourself? Consider for a moment embarking on the greatest adventure of all: an adventure within. Imagine spending time diving deep into your thoughts, unraveling your strengths, acknowledging your weaknesses, and understanding what makes you uniquely strong.</p>
      <p>No matter how many Facebook posts you scroll through, TikTok videos you watch, or YouTube reels you binge, there remains a void, an emptiness that can't be filled by external stimuli. These digital distractions might provide momentary entertainment, but they cannot offer the lasting satisfaction and fulfillment you crave. That fulfillment lies within you, waiting to be discovered on the page called "You." Yes, there is a page called "You."</p>
      
      <CardMedia
        component="img"
        height="200"
        image={thumbnail1}
        alt="Main Image"
        sx={{ marginY: 2 }}
      />
      
      <p>Deep down, you can sense it. There's more to you than what you've given away to the world. There's more to you than what you've experienced so far. You feel a hunger—a yearning for a more mature, wiser, wealthier, and healthier version of yourself.</p>
      <p>This journey of self-discovery requires the right environment, the right people, and the right places. You must immerse yourself in spaces that allow you to explore and understand the deeper facets of your being. Engage with people who encourage your growth, who challenge your perceptions, and who help you uncover the layers of your potential.</p>
      <p>Imagine the possibilities if you spent more time knowing yourself, delving into the treasures within you. What could you achieve? What new strengths could you uncover? There is so much untapped potential, so much hidden treasure within you, waiting to be shared with the world.</p>
      
      <CardMedia
        component="img"
        height="200"
        image={thumbnail2}
        alt="Theme Image"
        sx={{ marginY: 2 }}
      />
      
      <p>The path to this deeper understanding begins with a single step—choosing to prioritize your inner journey over external distractions. This is not just a journey for the sake of self-improvement, but a journey to uncover and give birth to your most profound ideas and aspirations.</p>
      <p>If this resonates with you, if you feel that stirring within to explore the uncharted territories of your own mind and spirit, then it's time to take action. Dive deeper into the journey of self-discovery with my book, <Link to="/buy" style={{textDecoration:'none'}}><em>Giving Birth to Ideas</em></Link>. This book is designed to guide you through the process of understanding yourself on a deeper level, nurturing your ideas, and bringing them to life. It's more than just a book—it's my true story poured out as a gift to you—it's a companion for your adventure into the depths of who you truly are.</p>
      <p>Are you ready to discover the treasure within you? Are you prepared to give birth to the ideas that will transform your life? Embrace the journey, and let <Link to="/buy" style={{textDecoration:'none'}}><em>Giving Birth to Ideas</em></Link> be your guide.</p>
      <p>Your friend,</p>
      <p>Lovemore</p>
    </Typography>
  );
}

export default Blog5;
