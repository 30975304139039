import * as React from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/X';
import logo from '../assets/destinyshaperscombined-bg.png';
import logoDark from '../assets/Destinyshapers-Africa-Dark-bg.png';
import { Divider } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const logoStyle = {
  width: '140px',
  height: 'auto',
};

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" mt={1} sx={{display:'flex', flexDirection:'row', alignItems:'center'}}>
      {'Copyright © '}
      Destiny Shapers&nbsp;
      {new Date().getFullYear()} 
      <span preserve>&nbsp;</span> | <span preserve>&nbsp;</span>
      <Link href="https://www.linkedin.com/in/timothy-tinashe-murambinda/" target='_blank' rel='noreferrer'>Developer&nbsp;</Link>
    </Typography>
  );
}

function Footer({mode}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const [openPP, setOpenPP] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isSubscribed, setIsSubscribed] = React.useState(false);

  React.useEffect(() => {
    const subscribed = localStorage.getItem('isSubscribed');
    if (subscribed) {
      setIsSubscribed(true);
    }
  }, []);

  const scrollToSection = (sectionId) => {
    if (location.pathname !== '/') {
      navigate('/', { state: { sectionId } });
    } else {
      const sectionElement = document.getElementById(sectionId);
      const offset = 128;
      if (sectionElement) {
        const targetScroll = sectionElement.offsetTop - offset;
        sectionElement.scrollIntoView({ behavior: 'smooth' });
        window.scrollTo({
          top: targetScroll,
          behavior: 'smooth',
        });
      }
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpenPP = () => {
    setOpenPP(true);
  };
  const handleClosePP = () => {
    setOpenPP(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const form = event.target;
    const data = new FormData(form);

    try {
      const response = await fetch(form.action, {
        method: form.method,
        body: data,
        headers: {
          'Accept': 'application/json'
        }
      });

      if (response.ok) {
        setIsSubscribed(true);
        localStorage.setItem('isSubscribed', 'true');
        form.reset();
      } else {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Form submission failed');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: 'center', md: 'left' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            minWidth: { xs: '100%', sm: '60%' },
          }}
        >
          <Box sx={{ width: { xs: '100%', sm: '60%' } }}>
            <Box sx={{ ml: '-15px' }}>
              { mode === 'light' ?
              <img
                src={
                  logo
                }
                style={logoStyle}
                alt="logo of Destinyshapers"
              />
              :
              <img
                src={
                  logoDark
                }
                style={logoStyle}
                alt="logo of Destinyshapers"
              />
              }
            </Box>
            <Typography variant="body2" fontWeight={600} gutterBottom>
              Newsletter
            </Typography>
            <Typography variant="body2" color="text.secondary" mb={2}>
              Subscribe to our newsletter for weekly updates and promotions.
            </Typography>
            <form name="newsletter" method="POST" action="https://formspree.io/f/mrbzzrvq" onSubmit={handleSubmit}>
              <Stack direction="row" spacing={1} useFlexGap>
                <TextField
                  name="email"
                  id="outlined-basic"
                  hiddenLabel
                  size="small"
                  variant="outlined"
                  fullWidth
                  aria-label="Enter your email address"
                  placeholder="Your email address"
                  inputProps={{
                    autoComplete: 'off',
                    'aria-label': 'Enter your email address',
                    disabled: isSubmitting || isSubscribed
                  }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  sx={{
                    cursor: 'pointer',
                    flexShrink: 0,
                    ...(isSubscribed && {
                      backgroundColor: 'white',
                      color: 'black',
                      fontWeight: 'bold'
                    })
                  }}
                  disabled={isSubmitting || isSubscribed}
                >
                  {isSubscribed ? 'Subscribed!' : 'Subscribe'}
                </Button>
              </Stack>
            </form>
          </Box>
        </Box>
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant="body2" fontWeight={600}>
            Navigate
          </Typography>
          <Link color="text.secondary" onClick={() => scrollToSection('testimonials')} style={{textDecoration: 'none', cursor:'pointer'}}>
            Testimonials
          </Link>
          <Link color="text.secondary" onClick={() => scrollToSection('highlights')} style={{textDecoration: 'none', cursor:'pointer'}}>
            Highlights
          </Link>
          <Link color="text.secondary" onClick={() => scrollToSection('faq')} style={{textDecoration: 'none', cursor:'pointer'}}>
            FAQs
          </Link>
          <Divider/>
          <Link color="text.secondary" onClick={() => navigate('/buy')} style={{textDecoration: 'none', cursor:'pointer'}}>
            Buy
          </Link>
          <Link color="text.secondary" onClick={() => navigate('/podcasts')} style={{textDecoration: 'none', cursor:'pointer'}}>
            Podcast
          </Link>
          <Link color="text.secondary" onClick={() => navigate('/blogs')} style={{textDecoration: 'none', cursor:'pointer'}}>
            Blog
          </Link>
        </Box>
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            gap: 1,
          }}
        >
          {/* <Typography variant="body2" fontWeight={600}>
            Company
          </Typography>
          <Link color="text.secondary" href="#">
            About us
          </Link>
          <Link color="text.secondary" href="#">
            Contact
          </Link> */}
        </Box>
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant="body2" fontWeight={600}>
            Legal
          </Typography>
          <Link color="text.secondary" onClick={handleClickOpen} style={{textDecoration: 'none', cursor:'pointer'}}>
            Terms
          </Link>
          <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              Destinyshapers Community Terms of Service
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
               <DialogContent dividers>
                <Typography gutterBottom variant="h6" fontSize="1rem" fontWeight="bold">
                  1. Members
                </Typography>
                <Typography gutterBottom>
                By becoming a member of the Destinyshapers Community, you agree to abide by these terms and conditions. We reserve the right to update these terms from time to time.
                </Typography>
                <Typography gutterBottom variant="h6" fontSize="1rem" fontWeight="bold">
                2. Code of Conduct
                </Typography>
                <Typography gutterBottom>
                All members are expected to interact respectfully with other community members and moderators. Hate speech, harassment, and any form of discrimination will not be tolerated.
                </Typography>
                <Typography gutterBottom variant="h6" fontSize="1rem" fontWeight="bold">
                3. Content Sharing
                </Typography>
                <Typography gutterBottom>
                You retain ownership of any content you share within the community but grant us a license to use, display, and distribute your content within the community platform.
                </Typography>
                <Typography gutterBottom variant="h6" fontSize="1rem" fontWeight="bold">
                4. Privacy
                </Typography>
                <Typography gutterBottom>
                We respect your privacy and are committed to protecting your personal information. Please review our Privacy Policy to understand how we collect, use, and protect your data.
                </Typography>
                <Typography gutterBottom variant="h6" fontSize="1rem" fontWeight="bold">
                5. Termination
                </Typography>
                <Typography gutterBottom>
                We reserve the right to terminate your membership if you violate these terms or engage in behavior that we deem harmful to the community.
                </Typography>
              </DialogContent>
          </BootstrapDialog>
          <Link color="text.secondary" onClick={handleClickOpenPP} style={{textDecoration: 'none', cursor:'pointer'}}>
            Privacy
          </Link>
          <BootstrapDialog
            onClose={handleClosePP}
            aria-labelledby="customized-dialog-title"
            open={openPP}
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              Destinyshapers Community Privacy Policy
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClosePP}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
               <DialogContent dividers>
                <Typography gutterBottom variant="h6" fontSize="1rem" fontWeight="bold">
                1. Data Collection
                </Typography>
                <Typography gutterBottom>
                We collect personal information that you provide when you join the Destinyshapers Community, including your name, email address, and any other information you choose to share.
                </Typography>
                <Typography gutterBottom variant="h6" fontSize="1rem" fontWeight="bold">
                2. Use of Information
                </Typography>
                <Typography gutterBottom>
                We use your information to manage your membership, send you updates and announcements, and improve the community experience.
                </Typography>
                <Typography gutterBottom variant="h6" fontSize="1rem" fontWeight="bold">
                3. Data Sharing
                </Typography>
                <Typography gutterBottom>
                We do not share your personal information with third parties without your consent, except as required by law or to protect our rights.
                </Typography>
                <Typography gutterBottom variant="h6" fontSize="1rem" fontWeight="bold">
                4. Security
                </Typography>
                <Typography gutterBottom>
                We implement appropriate security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction.
                </Typography>
                <Typography gutterBottom variant="h6" fontSize="1rem" fontWeight="bold">
                5. Your Rights
                </Typography>
                <Typography gutterBottom>
                You have the right to access, update, or delete your personal information at any time by contacting us.
                </Typography>
              </DialogContent>
          </BootstrapDialog>
        </Box>
      </Box>
      <Divider sx={{ width: '100%' }} />
      <Stack
        direction="row"
        spacing={1}
        sx={{
          display: 'flex',
          justifyContent: { xs: 'center', md: 'space-between' },
          width: '100%',
          flexWrap: 'wrap',
        }}
      >
        <Stack direction="row" spacing={2}>
          <IconButton href="https://www.facebook.com/profile.php?id=61557673321020&mibextid=ZbWKwL" target="_blank">
            <FacebookIcon />
          </IconButton>
          <IconButton href="https://www.linkedin.com/company/destinyshapers/" target="_blank">
            <LinkedInIcon />
          </IconButton>
          <IconButton href="https://x.com/Morespace2002/status/1767811051238666647" target="_blank">
            <TwitterIcon />
          </IconButton>
        </Stack>
        <Copyright />
      </Stack>
    </Container>
  );
}

Footer.propTypes = {
  mode: PropTypes.oneOf(['dark', 'light']).isRequired,
};

export default Footer;
